function cropImage(dataURI, callback) {
    const img = new Image();
  
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
  
      // Original image dimensions
      const { width: imgWidth, height: imgHeight } = img;
  
      // Calculate the crop region
      const cropWidth = imgWidth * 0.7; // Retain 70% of the width
      const cropHeight = imgHeight * 0.7; // Retain 70% of the height
      const startX = (imgWidth - cropWidth) / 2; // Center horizontally
      const startY = (imgHeight - cropHeight) / 2; // Center vertically
  
      // Set canvas dimensions to cropped size
      canvas.width = cropWidth;
      canvas.height = cropHeight;
  
      // Draw the cropped image onto the canvas
      context.drawImage(
        img, // Source image
        startX, startY, // Start of cropping region
        cropWidth, cropHeight, // Dimensions of cropping region
        0, 0, // Placement on canvas
        cropWidth, cropHeight // Scaled dimensions on canvas
      );
  
      // Get the cropped image as a Data URI
      const croppedDataURI = canvas.toDataURL("image/jpeg");
      callback(croppedDataURI);
    };
  
    img.onerror = (error) => {
      console.error("Failed to load image:", error);
      callback(error);
    };
  
    img.src = dataURI; // Load the image
  }
  
  export default cropImage;
 
  