import close from "../../assets/illustrations/close.png";
import { fontStyle } from "../../Utils/tpscanStyle";
const HealthPopup = (props) => {
    const { popupType, setPopup, contentType } = props;
    const content = {
        cavity:
            "Sâu răng là những lỗ nhỏ trên răng do sâu răng gây ra. Chúng hình thành khi mảng bám và vi khuẩn làm hỏng men răng, lớp ngoài cùng của răng.",
        tartar:
            "Mảng bám là một lớp màng vi khuẩn dính và không màu hình thành trên răng của bạn. Nếu không được làm sạch, nó có thể cứng lại thành cao răng, một lớp lắng đọng màu vàng hoặc nâu.",
        gum_recession:
            "Tụt nướu xảy ra khi nướu xung quanh răng của bạn co rút, làm lộ nhiều hơn phần răng hoặc chân răng.",
        gum_swelling:
            "Sưng nướu xảy ra khi nướu của bạn trở nên sưng phồng, đỏ và đau. Đây thường là dấu hiệu của kích ứng hoặc nhiễm trùng và có thể khiến nướu trông to hơn bình thường.",
        gaps_poorly_aligned_teeth:
            "Khe hở là khoảng trống giữa các răng, trong khi răng khấp khểnh là những răng không thẳng hàng hoặc không đúng vị trí.",
    };
    return (
        <div className="z-30 fixed h-full w-full top-0 left-0 bg-black/[0.5] flex justify-center items-center">
            <div className=" bg-white w-4/5 p-3">
                <div className="flex justify-between px-3 py-3 border-b-2">
                    <p className="text-base font-semibold">{popupType} Thông tin sức khỏe.</p>
                    <button
                        className=""
                        onClick={() => {
                            setPopup(false);
                        }}
                    >
                        <img src={close} className="w-6 h-6" alt="close" />
                    </button>
                </div>
                <div className="p-3">{content[contentType]}</div>
            </div>
        </div>
    );
};
export default HealthPopup;