import { Route, Routes } from "react-router-dom"
import LandingPage from "./PgLandingPage/landingPage"
import FullScan from "./PgScan/fullScan"
import ThankYou from "./PgThanks/thanks"
import PageNotFound from "./PageNotFound/pageNotFound"
import PGCode from "./PdCodeValidator"
import PgTpScanLanding from "./PgLandingPage/landingPage"

const Pg = () => {
    return (
        <div className="h-full">
            <Routes>
                <Route path="/" element={<PgTpScanLanding />} />
                <Route path="auto" element={<LandingPage />} />
                <Route path="scan" element={<FullScan />} />
                <Route path="thanks" element={<ThankYou />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </div>
    )
}

export default Pg