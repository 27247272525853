import React from 'react';
import PepsodentGumExpertLogo from '../../../assets/periGum/pepsodent-gum-expert-logo.png';
import BuyNowImageBassha from '../../../assets/periGum/buy-now-icon.png';
import BuyNowImageEnglish from "../../../assets/periGum/buynow-english.png";
import BuyNowImageVietnam from "../../../assets/periGum/buynow-vietnam.png";
import PeriPackShotTube from '../../../assets/periGum/peri-packshot-tube.png';

const LastPage = () => {
  const language = localStorage.getItem("userLanguage") || "en";

  const buyNowImage = language === "vn" ? BuyNowImageVietnam : language === "bs" ? BuyNowImageBassha : BuyNowImageEnglish;

  return (
    <div>
      <div className='pt-14 text-right pb-10 pr-8'>
        <img className='inline-block' src={PepsodentGumExpertLogo} alt='logo' />
      </div>

      <div
        className='flex pt-8 pr-8'
        style={{
          backgroundImage: `url(${PeriPackShotTube})`,
          backgroundSize: '100% 100%',
          minHeight: '80vh'
        }}
      >
        <div className='pt-14 text-right w-full mt-[25px]'>
          <img className='inline-block' src={buyNowImage} alt='buy now' />
        </div>
      </div>
    </div>
  );
};

export default LastPage;
