import { Navigate, Route, Routes } from "react-router-dom"
import ScanPage from "./Scan";
import LandingPage1 from "./LandingPage/landingPage1";
import LandingPage2 from "./LandingPage/landingPage2";
import LandingPage3 from "./LandingPage/landingPage3";
import ReportSummary from "./ReportSummary";
import GumHealthReport from "./GumHealthReport";
import LastPage from "./ReportSummary/lastPage";

const PeriGumCheck = () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/peri-gum/en" />} />
            <Route path="/:language" element={<LandingPage1 />} />
            <Route path="/landing-page-1" element={<LandingPage2 />} />
            <Route path="/landing-page-2" element={<LandingPage3 />} />
            <Route path="/scan" element={<ScanPage />} />
            <Route path="/report-summary" element={<ReportSummary />} />
            <Route path="/health-report" element={<GumHealthReport />} />
            <Route path="/thank-you" element={<LastPage />} />
        </Routes>
    )
}

export default PeriGumCheck;