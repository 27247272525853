
import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../style/peri-check.css";
import { useTranslation } from "react-i18next";

const ProfileForm = ({ form1Values, setIsForm1, setForm1Values }) => {
    const { t } = useTranslation("f1");

    const validationSchema = Yup.object({
        name: Yup.string().required(t("name_required")),
        email: Yup.string()
            .email(t("invalid_email"))
            .required(t("email_required")),
        gender: Yup.string().required(t("gender_required")),
        age: Yup.string().required(t("age_required")),
    });

    return (
        <Formik
            initialValues={form1Values}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                setForm1Values(values);
                setIsForm1(false);
            }}
        >
            {({ values, setFieldValue, handleBlur, errors, touched }) => (
                <Form className="flex-grow h-full">
                    <p className=" text-peri-blue font-semibold text-3xl px-5 my-10 font-cera-pro-medium">
                        {t("form_title")}
                    </p>
                    <div className="px-5">
                        {/* Name Field */}
                        <Field
                            name="name"
                            type="text"
                            placeholder={t("form_name")}
                            className=" font-cera-pro-medium py-3 w-full border border-b-2 peri-border border-t-0 border-l-0 border-r-0 focus:outline-none focus:ring-0"
                        />
                        <ErrorMessage name="name" component="div" className="text-red-500 text-sm mt-1" />

                        {/* Email Field */}
                        <Field
                            name="email"
                            type="text"
                            placeholder={t("form_email")}
                            className="font-cera-pro-medium py-3 w-full mt-3 border border-b-2 peri-border border-t-0 border-l-0 border-r-0 focus:outline-none focus:ring-0 "
                        />
                        <ErrorMessage name="email" component="div" className="text-red-500 text-sm mt-1" />

                        {/* Gender Buttons */}
                        <div className="flex gap-2 mt-4">
                            <button
                                type="button"
                                className={` font-cera-pro-medium font-semibold text-base  flex-grow py-4 border border-[#7C7F81] rounded-full transition ${values.gender === "male" ? "bg-red-100 " : "bg-white text-[#b2b2b2]"} `}
                                onClick={() => {
                                    setFieldValue("gender", "male");
                                    handleBlur("gender");
                                }}
                            >
                                {t("male-button")}
                            </button>
                            <button
                                type="button"
                                className={` font-cera-pro-medium font-semibold text-base flex-grow py-4 border border-[#7C7F81]  rounded-full transition ${values.gender === "female" ? "bg-red-100" : "bg-white text-[#b2b2b2]"}`}
                                onClick={() => {
                                    setFieldValue("gender", "female");
                                    handleBlur("gender");
                                }}
                            >
                                {t("female-button")}
                            </button>
                        </div>
                        <ErrorMessage name="gender" component="div" className="text-red-500 text-sm mt-1" />

                        {/* Age Buttons */}
                        <div className="flex gap-2 mt-5">
                            {["< 30", "30 - 50", "> 50"].map((ageOption) => (
                                <button
                                    key={ageOption}
                                    type="button"
                                    className={`font-cera-pro-medium font-semibold text-base flex-grow py-4 border border-[#7C7F81]  rounded-full transition ${values.age === ageOption ? "bg-red-100" : "bg-white text-[#b2b2b2]"}`}
                                    onClick={() => {
                                        setFieldValue("age", ageOption);
                                        handleBlur("age");
                                    }}
                                >
                                    {ageOption}
                                </button>
                            ))}
                        </div>
                        <ErrorMessage name="age" component="div" className="text-red-500 text-sm mt-1" />
                    </div>

                    {/* Submit Button */}
                    <div className="flex justify-center py-10">
                        <button
                            type="submit"
                            className="mt-6 border border-[#7C7F81] text-peri-blue text-center bg-white rounded-lg font-cera-pro-medium font-semibold text-2xl"
                            style={{ width: '159px', height: '50px', borderRadius: '25px' }}
                        >
                            {t("submit-button")}
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default ProfileForm;
