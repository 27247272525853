import demoImage from "../../../assets/demoImages/demoImage.jpg"
import tapIcon from "../../../assets/Icons/tap_icon.png"

const FaceDemoScreen = ({ gotIt, back }) => {

    return (
        <div className="h-full w-full ">
            <div className=" h-3/5 w-full pt-4 px-4 bg-slate-100 ">
                <img src={demoImage} alt="ai-teeth" className="h-full w-full object-contain  " />
            </div>
            <div className="h-2/5  bg-slate-100 ">
                <div className=" h-full w-full flex flex-col justify-around px-8">
                    <div className=" font-urbanist font-semibold text-lg  text-center px-10 sm600:text-3xl">
                        Align your head inside the circle, flash that big smile
                    </div>
                    <div className=" relative grid grid-cols-12 gap-4 mx-6 py-3 pt-4 rounded-xl bg-white">
                        <div className="col-span-2 flex items-center justify-center mx-4 h-full w-full ">
                            <img src={tapIcon} alt="alt Icon" className="h-8" />
                        </div>
                        <div className="absolute left-[20%] top-1/3 h-1/3 w-0.5 bg-slate-200"></div>

                        <div className="col-span-10 text-center pl-3 text-lg font-urbanist font-semibold pr-2 sm600:text-3xl">
                            Tap anywhere on the screen to snap the pic
                        </div>
                    </div>

                    <button
                        type="button"
                        className="text-base font-red-hat h-12 rounded-lg bg-black text-white"
                        onClick={() => {
                            gotIt()
                        }}
                    >
                        Got it
                    </button>

                </div>
            </div>


        </div>
    )
}

export default FaceDemoScreen