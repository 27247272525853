import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import 'react-html5-camera-photo/build/css/index.css';
import back from "../../../assets/Icons/Back-white.png"
import demoIcon from "../../../assets/Icons/image-reference.png"
import DemoScreens from "./demoScreens"
import Preview from "./preview"
import "./index.css"
import steps from "./steps";
import PermissionPopup from "./cameraPermissionPopup";
import { GetBrowserDetails } from '../../../Utils/deviceDetails';
import { GetMobileOperatingSystem } from '../../../Utils/getOS';
import { fontStyle, fontWeight, buttonStyle, companyBucket } from "../../../Utils/tpscanStyle"
import FrontCameraError from "./frontCameraError";
import { postLogDetails } from '../../../Services/APIs/appDetails';
import teethSides from '../../../Utils/teethSideNames';
import { notifyCameraError } from '../../../Services/APIs/scan';
import { getCompleteDeviceDetails } from '../../../Utils/comepleteDeviceInfo';

const Shoot = (props) => {
    const { dentalOfficeName } = useParams();
    const [facialMode, setFacialMode] = useState(FACING_MODES.USER)
    const [frontCameraError, setFrontCameraError] = useState(false)
    const { activeImageSide, setToothPicker, quickScan, setImageSide } = props
    const navigate = useNavigate()
    const [isDemo, setDemo] = useState(true)
    const [isCamera, setCamera] = useState(true)
    const [capturedImage, setCapturedImage] = useState()
    const [flashLight, setFlashLight] = useState(false)
    const [mediaStream, setStream] = useState(null)
    const [errorType, setErrorType] = useState({ isError: false, type: "ios" })
    const OS = GetMobileOperatingSystem()   // operating system
    const uid = JSON.parse(localStorage.getItem("usertpi") || '{}').usertpi
    const app = `${JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket}${localStorage.getItem("isUnileverIpad") ? "-ipad" : ""}`



    const handleCameraType = () => {
        OS === "iOS" ? handleWithFacialMode() : handleWithLabel()
    }

    const handleWithFacialMode = () => {
        navigator.mediaDevices.getUserMedia({ video: { facingMode: FACING_MODES.USER } })
            .then((stream) => {
                setFacialMode(FACING_MODES.USER)
                postLogDetails({ uid, app, payLoad: "iOS - found front camera" })
            })
            .catch((error) => {
                notifyCameraError({ info: { ...getCompleteDeviceDetails(), uid, app, error: JSON.stringify(error) } })
                setFrontCameraError(true)
                setFacialMode(FACING_MODES.ENVIRONMENT)
            });
    }

    const handleWithLabel = () => {
        navigator.mediaDevices.enumerateDevices().then(devices => {
            const videoDevices = devices.filter(device => device.kind === 'videoinput');

            const frontCamera = videoDevices.find(device =>
                device.label.toLowerCase().includes('front') ||
                device.label.toLowerCase().includes('depan') ||
                device.label.toLowerCase().includes('trước')
            );

            if (frontCamera) {
                setFacialMode(frontCamera.deviceId); // Set the deviceId directly
                postLogDetails({ uid, app, payLoad: "Android - found front camera" })
            } else {
                notifyCameraError({ info: { ...getCompleteDeviceDetails(), uid, app, error: JSON.stringify(videoDevices) } })
                setFacialMode(FACING_MODES.ENVIRONMENT); // Fallback to rear camera
                setFrontCameraError(true)
            }
        }).catch(err => console.error("Error enumerating devices: ", err));
    }


    const handleTakePhoto = (dataUri) => {
        setCapturedImage(dataUri)
        postLogDetails({
            uid: JSON.parse(localStorage.getItem("usertpi")).usertpi,
            app,
            payLoad: `captured ${teethSides[activeImageSide]} teeth`
        })
        setTimeout(() => {
            setCamera(false)
        }, 400)
        stopCamera()
    }

    const cameraStarted = (stream) => {
        const isInAppBrowser = () => {
            const ua = navigator.userAgent || navigator.vendor || window.opera;
            return (
                ua.includes("FBAN") || ua.includes("FBAV") || // Facebook
                ua.includes("Instagram") || // Instagram
                ua.includes("Line") || // Line App
                ua.includes("Snapchat") || // Snapchat
                ua.includes("WhatsApp") || // WhatsApp
                ua.includes("Twitter") // Twitter
            );
        };

        const checkCameraAvailability = async () => {
            try {
                // Try accessing the camera
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });

                // If successful, stop the stream to free the camera
                stream.getTracks().forEach(track => track.stop());

            } catch (error) {
                if (error.name === "NotReadableError") {
                    // Another app is using the camera
                    alert("The camera is currently being used by another application. Please close any other app using the camera and try again.");
                } else if (error.name === "NotAllowedError") {
                    // User denied permission
                    alert("Camera access was denied. Please allow camera permissions and try again.");
                } else {
                    console.error("Camera error:", error);
                }
            }
        };

        // Show alert if in an in-app browser
        if (isInAppBrowser()) {
            alert("For a better experience, please open this link in Safari or Chrome.");
        }

        // Check camera availability when the page loads
        checkCameraAvailability();
        setStream(stream)
        handleCameraType()
        setErrorType({ isError: false, type: "ios" })
    }

    const stopCamera = () => {
        // console.log("Tracks before stopping:", mediaStream.getTracks()); // Log tracks
        if (mediaStream) {
            mediaStream.getTracks().forEach((track) => {
                // console.log(`Stopping track with kind: ${track.kind}, readyState: ${track.readyState}`);
                postLogDetails({ uid, app, payLoad: `Stopping track with kind: ${track.kind}, readyState: ${track.readyState}` })
                track.stop()
                // console.log(`Track stopped. New readyState: ${track.readyState}`);
                postLogDetails({ uid, app, payLoad: `Track stopped ${track.kind}, readyState: ${track.readyState}` })
            });
            setStream(null);
        }
    };

    const checkError = () => {
        const browser = GetBrowserDetails();

        if (browser.name === "Mobile Chrome") {
            navigator.permissions.query({ name: 'camera' }).then((res) => {
                if (res.state === "denied") {
                    if (OS === 'iOS') {
                        setErrorType({ isError: true, type: "ios" })
                    } else {
                        setErrorType({ isError: true, type: "android" })
                    }
                }
            }).catch((error) => {
                setErrorType({ isError: true, type: "ios" })
            })

        } else {
            setErrorType({ isError: true, type: "ios" })
        }

    }

    const OvuleShape = () => {
        return (
            <div className={`ovule-container w-full flex flex-col justify-center items-center `}>
                <div className={`relative ovule ovule-${activeImageSide} ${activeImageSide === 4 && isCamera && "ovule-4-reversed"}  `}>
                    <p
                        className={`absolute -bottom-10 w-full text-center text-base text-white my-3 md:text-2xl ${activeImageSide === 4 && isCamera && "ransform rotate-180 "} `}>
                        {steps[activeImageSide].teeth_side}
                    </p>
                </div>
            </div>
        )
    }


    const TopLayer = () => {
        return (
            <div className='camera-top-layer-2 h-full absolute top-0 text-white w-full'>
                <div className='flex justify-between items-center h-13 px-2'>
                    <button type='button' onClick={() => { window.history.back() }}> <img src={back} alt="back" className="w-8 h-8 md:w-12 md:h-12" /> </button>
                    <div className='pt-2'>
                        <button type='button' onClick={() => { setDemo(true) }} > <img src={demoIcon} alt="back" className="w-12 h-12 md:h-20 md:w-20 " /> </button>
                    </div>
                </div>
                <p
                    className={`text-base mt-16 text-center md:text-2xl ${activeImageSide === 4 && isCamera && "ransform rotate-180 "}`}
                >
                    {isCamera ? "Chạm vào màn hình để chụp" : `Chạm vào màn hình để chụp`}
                </p>
            </div>
        )
    }


    return (
        <>
            {isDemo ? <DemoScreens activeImageSide={activeImageSide} setToothPicker={setToothPicker} setDemo={setDemo} setCamera={setCamera} quickScan={quickScan} /> :
                <div className='h-screen'>
                    {errorType.isError && <PermissionPopup info={errorType} setErrorType={setErrorType} />}
                    {
                        isCamera ?
                            <>
                                <Camera
                                    onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                                    idealFacingMode={facialMode}
                                    isFullscreen={false}
                                    isMaxResolution={true}
                                    imageType={IMAGE_TYPES.JPG}
                                    isImageMirror={facialMode !== FACING_MODES.ENVIRONMENT}
                                    imageCompression={0.6}
                                    sizeFactor={1}
                                    videoConstraints={{
                                        facingMode: { exact: "user" }, // Force front camera
                                        width: { ideal: window.innerWidth },
                                        height: { ideal: window.innerHeight },
                                        aspectRatio: { ideal: window.innerWidth / window.innerHeight }
                                    }}
                                    onCameraStart={cameraStarted}
                                    onCameraError={checkError}
                                    className="WebCam"
                                />
                                {!errorType.isError && frontCameraError && <FrontCameraError back={setFrontCameraError} />}

                            </> : <Preview setImageSide={setImageSide} image={capturedImage} setCamera={setCamera} setToothPicker={setToothPicker} activeImageSide={activeImageSide} setDemo={setDemo} quickScan={quickScan} />
                    }
                    <OvuleShape />
                    <TopLayer />

                </div >
            }
        </>
    )
}

export default Shoot