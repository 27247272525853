import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { periProfileData } from '../../../Services/APIs/profile';
import { useTranslation } from "react-i18next";

const Questionnaire = ({ setFormOpen, form1Values, setFormCompleted, submit }) => {
    const { t, i18n } = useTranslation("f2");
    const [loading, setLoading] = useState(false)
    const questions = t("questions", { returnObjects: true });
    const questionsInEnglish = [
        "Do you smoke?",
        "Do you suffer from diabetes?",
        "Are you pregnant or just delivered a baby?",
        "Do you have crowns, braces or implants in your mouth?",
        "Do any of your teeth feel loose?"
    ];

    const validationSchema = Yup.object().shape(
        Object.fromEntries(
            questionsInEnglish.map((question) => [question, Yup.string().required(t("required_field"))])
        )
    );

    return (
        <Formik
            initialValues={Object.fromEntries(questionsInEnglish.map((q) => [q, ""]))}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                setLoading(true);
                try {
                    const info = {
                        uid: JSON.parse(localStorage.getItem("usertpi") || "{}").usertpi,
                        ...form1Values,
                        questionnaire: Object.entries(values).map(([question, answer]) => ({ [question]: answer })),
                        language: i18n.language
                    };
                    localStorage.setItem("peri-user-questionnaire", JSON.stringify(info));
                    await periProfileData(info);
                    submit();
                } catch (error) {
                    setErrors({ submit: t("submission_error") });
                } finally {
                    setSubmitting(true);
                }
            }}
        >
            {({ values, setFieldValue, isSubmitting, errors }) => (
                <Form className="flex flex-col items-start p-6 flex-grow">
                    {questions.map((question, index) => (
                        <div key={index} className="mb-4 w-full">
                            <p className="text-lg mb-2 text-peri-blue font-cera-pro-medium">{question}</p>
                            <div className="flex space-x-4">
                                <button
                                    type="button"
                                    onClick={() => setFieldValue(questionsInEnglish[index], 'yes')}
                                    className={` text-base border border-[#7C7F81] rounded-full transition-all duration-200 font-cera-pro-medium ${values[questionsInEnglish[index]] === 'yes' ? 'bg-red-100' : 'text-[#b2b2b2]'}`}
                                    style={{ height: '50px', width: '166px' }}
                                >
                                    {t("yes")}
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setFieldValue(questionsInEnglish[index], 'no')}
                                    className={`text-base border border-[#7C7F81] rounded-full font-cera-pro-medium transition-all duration-200 ${values[questionsInEnglish[index]] === 'no' ? 'bg-red-100' : 'text-[#b2b2b2]'}`}
                                    style={{ height: '50px', width: '166px' }}
                                >
                                    {t("no")}
                                </button>
                            </div>
                            <ErrorMessage name={questionsInEnglish[index]} component="div" className="text-red-500 text-sm mt-1" />
                        </div>
                    ))}

                    <div className="flex flex-col items-center justify-center w-full">
                        <button
                            type="submit"
                            className="mt-6 border border-[#7C7F81] text-peri-blue text-2xl bg-white  text-center rounded-lg  font-cera-pro-medium font-semibold"
                            style={{ width: '159px', height: '50px', borderRadius: '25px' }}
                            disabled={isSubmitting}

                        >
                            {loading ? <div className="loader-container my-1"><div className="loader dark-loader"></div></div> : <span>{t("submit-button")}</span>}
                        </button>
                        <ErrorMessage name="submit" component="p" className="text-red-600 text-base" />
                    </div>
                </Form>
            )}
        </Formik>

    );
};

export default Questionnaire;