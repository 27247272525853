import { useState } from "react";
import fairStatus from "../../assets/vietnam/fair.png"
import goodStatus from "../../assets/vietnam/good.png";
import poorStatus from "../../assets/vietnam/poor.png";
import infoIcon from "../../assets/Icons/info.png";
import HealthPopup from "../ScoreCard/healthPopup";
import { fontStyle, fontWeight, buttonStyle } from "../../Utils/tpscanStyle";
const TpInfo = () => {
    const teethHealth = JSON.parse(localStorage.getItem("tpscore"));

    const status = [
        { type: "cavity", title: "Sâu răng", img: goodStatus },
        { type: "tartar", title: "Mảng bám & vôi răng", img: fairStatus },
        { type: "gum_recession", title: "Tụt nướu", img: poorStatus },
        { type: "gum_swelling", title: "Sưng/ Viêm nướu", img: poorStatus },
        // { type: "gaps_poorly_aligned_teeth", title: "Khe hở và răng lệch lạc", img: poorStatus }

    ];

    const [popup, setPopup] = useState(false);
    const [popupType, setPopupType] = useState();
    const [contentType, setContentType] = useState();

    const openPopup = (data) => {
        setPopupType(data.title);
        setContentType(data.type)
        setPopup(true);
    };

    const Health = (props) => {
        const { data } = props;
        let text = "";
        let imgSrc = "";

        if (data.type === "cavity") {
            if (teethHealth.cavity_score == 0) {
                text = "";
                imgSrc = goodStatus;
            } else {
                text =
                    "Sâu răng không được chữa trị có thể dẫn đến sưng đau nghiêm trọng & nhiễm trùng";
                imgSrc = poorStatus;
            }
        }
        if (data.type === "gaps_poorly_aligned_teeth") {
            if (teethHealth.gaps_poorly_aligned_teeth) {
                text =
                    "Severe misalignment can lead to jaw pain and significant dental problems";
                imgSrc = poorStatus;
            } else {
                text =
                    "Untreated dental cavities can lead to severe pain and infections";
                imgSrc = fairStatus;
            }
        }
        if (data.type === "tartar") {
            if (teethHealth.tartar_score == 0) {
                text = "";
                imgSrc = goodStatus;
            } else if (teethHealth.tartar_score <= 5) {
                text = "Moderate plaque buildup can lead to gingivitis if not treated";
                imgSrc = fairStatus;
            } else {
                text = "Mảng bám có thể gây sâu răng & các bệnh về nướu";
                imgSrc = poorStatus;
            }
        }
        if (data.type === "gum_recession") {
            if (teethHealth.gum_recession == 0) {
                text = "";
                imgSrc = goodStatus;
            } else if (teethHealth.gum_recession <= 5) {
                text = "Tụt nướu răng ở giai đoạn đầu có thể làm răng ê buốt";
                imgSrc = fairStatus;
            } else {
                text =
                    "Tụt nướu có thể làm lộ chân răng, gây yếu răng hoặc mất răng ";
                imgSrc = poorStatus;
            }
        }
        if (data.type === "gum_swelling") {
            if (teethHealth.gum_swelling == 0) {
                text = "";
                imgSrc = goodStatus;
            } else if (teethHealth.gum_swelling <= 5) {
                text = "Nướu sưng nhẹ có thể là triệu chứng của bệnh viêm nướu";
                imgSrc = fairStatus;
            } else {
                text =
                    "Sưng, viêm nướu có thể dẫn đến các bệnh nghiêm trọng về nướu & nhiễm trùng";
                imgSrc = poorStatus;
            }
        }

        return (
            <div>
                <div className="my-2  mx-5 flex justify-start items-center">
                    <div className="my-1   ">
                        <span
                            className="my-1 text-base"
                            style={{ ...fontWeight, ...fontStyle, fontSize: "16px" }}
                        >
                            {data.title}
                        </span>
                        <button
                            type="button"
                            className="mb-1 mx-2"
                            onClick={() => {
                                openPopup(data);
                            }}
                        >
                            <img src={infoIcon} alt="info" className="h-3" />
                        </button>
                    </div>
                    <div className="ms-auto  ">
                        {imgSrc && (
                            <img src={imgSrc} alt="status" className="h-5 me-1 mt-2" />
                        )}
                    </div>
                </div>
                {/* <div className="mx-5 opacity-75" style={{...fontStyle}}>{text}</div> */}
            </div>
        );
    };

    return (
        <>
            {status.map((eachStatus, i) => (
                <Health key={i} data={eachStatus} />
            ))}
            {popup && (
                <HealthPopup popupType={popupType} contentType={contentType} data={"data"} setPopup={setPopup} />
            )}
        </>
    );
};

export default TpInfo;