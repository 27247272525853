import React from "react";
import { fontStyle, buttonStyle, fontWeight } from "../../Utils/tpscanStyle";
import gum_recession from "../../assets/bernImages/Rectangle 41090.png";
import gum_swelling from "../../assets/bernImages/Rectangle 41091.png";
import tartar from "../../assets/bernImages/Rectangle 41092.png";
import cavity from "../../assets/bernImages/Rectangle 41093.png"
const TpInfoLegends = () => {

    return (
        <div className="pb-8 pt-2">
            <div className="flex mx-4 mt-4">
                <img className="h-6" src={cavity} alt="Legends" />
                <span className="mx-4  text-base" style={{ ...fontWeight, ...fontStyle }}>Tình trạng sâu răng</span>
            </div>
            <div className="flex mx-4 mt-2">
                <img className="h-6" src={tartar} alt="Legends" />
                <span className="mx-4  text-base" style={{ ...fontWeight, ...fontStyle }}>Mức độ tích tụ mảng bám & vôi răng</span>
            </div>
            <div className="flex mx-4 mt-2">
                <img className="h-6" src={gum_recession} alt="Legends" />
                <span className="mx-4  text-base" style={{ ...fontWeight, ...fontStyle }}>Tình trạng viêm nướu/lợi</span>
            </div>
            <div className="flex mx-4 mt-2">
                <img className="h-6" src={gum_swelling} alt="Legends" />
                <span className="mx-4  text-base" style={{ ...fontWeight, ...fontStyle }}>Tình trạng tụt nướu/lợi</span>
            </div>
        </div>
    );

};

export default TpInfoLegends;