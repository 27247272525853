import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import SubmitReport from "./reportSubmit"
import Shoot from "./shoot"
import { useSelector } from "react-redux"


const FullScan = () => {
    const navigate = useNavigate()
    const threePhotosSteps = [0, 3, 4]
    const { images } = useSelector((state) => state.fullScan);
    const [activeImageSide, setImageSide] = useState(images.length < 3 ? threePhotosSteps[images.length] : 0)
    const [isToothPicker, setToothPicker] = useState(false)

    useEffect(() => {
        if (!localStorage.getItem("usertpi") || localStorage.getItem("isScanCompleted")) {
            // navigate(`/vietnam`)
        }

        images.length >= 3 ? setToothPicker(true) : setToothPicker(false)

    }, [activeImageSide])

    return (
        <div className="h-full">
            {
                isToothPicker ?
                    <SubmitReport /> :
                    <Shoot activeImageSide={activeImageSide} setToothPicker={setToothPicker} quickScan={false} setImageSide={setImageSide} />
            }
        </div>
    )
}

export default FullScan