export const gumHealthAnalysis = ({ tpscore, user_info, riskactorsList }) => {
    let result = []
    let risk_factor = []

    const { tartar_score, gum_swelling, gum_recession } = tpscore
    const questionnaire = user_info?.questionnaire.reduce((acc, obj) => ({ ...acc, ...obj }), {});

    const getBoolean = (string) => {
        return string === "yes" ? true : false
    }
    const analysisCard = [
        "healthy gums",
        "at risk of gingivitis",
        "gingivitis",
        "advanced gum disease",
    ]

    const smoking = getBoolean(questionnaire["Do you smoke?"])
    const pregnant = getBoolean(questionnaire["Are you pregnant or just delivered a baby?"])
    const diabetes = getBoolean(questionnaire["Do you suffer from diabetes?"])
    const implants = getBoolean(questionnaire["Do you have crowns, braces or implants in your mouth?"])
    const toothLoose = getBoolean(questionnaire["Do any of your teeth feel loose?"])


    if (smoking) risk_factor.push(riskactorsList[0])
    if (pregnant) risk_factor.push(riskactorsList[1])
    if (diabetes) risk_factor.push(riskactorsList[2])
    if (implants) risk_factor.push(riskactorsList[3])
    if (toothLoose) risk_factor.push(riskactorsList[4])


    if (tartar_score === 0 && gum_swelling === 0 && gum_recession === 0) {
        if (smoking) {
            result.push(analysisCard[1])
        } if (pregnant) {
            result.push(analysisCard[1])
        } if (diabetes) {
            result.push(analysisCard[1])
        } if (implants) {
            result.push(analysisCard[1])
        } if (toothLoose) {
            result.push(analysisCard[3])
        }
    } if (!smoking && !pregnant && !diabetes && !implants && !toothLoose) {
        if (tartar_score > 0) {
            result.push(analysisCard[2])
        } if (gum_recession > 0) {
            result.push(analysisCard[3])
        }
        // need tooth loose from ml response.  where shaky tooth === tooth loose
    } else if (tartar_score > 0) {
        if (smoking) {
            result.push(analysisCard[1])
        } if (implants) {
            result.push(analysisCard[1])
        } if (pregnant) {
            result.push(analysisCard[1])
        } if (diabetes) {
            result.push(analysisCard[2])
        }

    } if (gum_swelling > 0 && (smoking || pregnant || implants)) {
        result.push(analysisCard[2])
    } if (gum_recession > 0 && (diabetes || smoking || pregnant || implants || toothLoose)) {
        result.push(analysisCard[3])
    } if (gum_swelling > 0 && diabetes) {
        result.push(analysisCard[3])
    } if (tartar_score === 0 && gum_swelling === 0 && gum_recession === 0 && !smoking && !pregnant && !diabetes && !implants && !toothLoose) {
        result = ["healthy gums"]
    }

    const multiple_results = [...new Set(result)];
    const final_result_id = Math.max(...multiple_results.map((issue) => analysisCard.indexOf(issue)));
    const final_result = analysisCard[final_result_id]
    const final_risk_factor = risk_factor.length === 0 ? riskactorsList[5] : risk_factor.join(", ")

    return { final_result, final_risk_factor }
}