import React, { useEffect } from "react";
import PeriButton from "../Components/Button";

import '../style/peri-check.css'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const LandingPage3 = () => {
    const navigate = useNavigate();
    const { t } = useTranslation("l3")

    useEffect(() => {
        setTimeout(() => {
            navigate("/peri-gum/scan")
        }, 3500)

    }, [])

    return (
        <div className="relative w-full h-full bg-cover bg-center">
            <div className="w-full background-image1 p-2 h-[45%]">
                <div className="w-full m-auto h-full p-8 flex justify-center flex-col">
                    <p className="text-peri-secondary text-2xl mb-7 font-bold font-cera-pro-black"> {t("header1")}
                        <span className="text-peri-primary"> {t("header2")}</span>
                    </p>
                    {localStorage.getItem("i18nextLng") === "vn" && <p className="font-cera-pro-medium text-peri-blue -mt-3 mb-3 text-base">*Theo Hội Răng Hàm Mặt Việt Nam</p>}
                    <p className={`text-peri-secondary mb-7 font-cera-pro-medium  ${t("fontBold")}`}>{t("body1")}<span className="text-peri-primary"> {t("body2")} </span>{t("body3")}</p>

                    <PeriButton text={t("callToAction")} />
                </div>
            </div>
            <div className="w-full h-[55%] background-image2 ">
            </div>
        </div>
    );
};

export default LandingPage3;
