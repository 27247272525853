import { Route, Routes } from "react-router-dom"
import LandingPage from "./LandingPage"
import Scan from "./Scan"
import ThankYou from "./Thanks"
import PageNotFound from "./PageNotFound"

const StarInsurance = () => {
    return (
        <div className="h-full">
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="scan" element={<Scan />} />
                <Route path="thanks" element={<ThankYou />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </div>
    )
}

export default StarInsurance