import { useState } from "react"
import back from "../../../assets/periGum/back.png"
import close from "../../../assets/periGum/close.png"
import ProfileForm from "./profile"
import ReasonsForm from "./reasons"
import { useNavigate } from "react-router-dom"

const Form = ({ setFormOpen, setFormCompleted, submit }) => {
    const navigate = useNavigate()
    const [isForm1, setIsForm1] = useState(true)
    const [form1Values, setForm1Values] = useState({
        name: "",
        email: "",
        gender: "",
        age: ""
    })

    const goBack = () => {
        isForm1 ? setFormOpen(false) : setIsForm1(true)
    }
    return (
        <div className="h-full flex flex-col">
            <div className="flex justify-between p-2 mb-5">
                <button type="button" onClick={goBack} >
                    <img src={back} alt="back button" className="h-9" />
                </button>
                <button type="button" onClick={goBack} >
                    <img src={close} alt="back button" className="h-9" />
                </button>
            </div>
            {
                isForm1 ? <ProfileForm form1Values={form1Values} setIsForm1={setIsForm1} setForm1Values={setForm1Values} />
                    : <ReasonsForm setFormOpen={setFormOpen} form1Values={form1Values} setFormCompleted={setFormCompleted} submit={submit} />
            }


        </div>
    )
}

export default Form