import welcomeTeeth from "../../../assets/illustrations/welcomeTeeth.png";
import toothlensLogo from "../../../assets/illustrations/Toothlens_logo.png";
import { useEffect, useState } from "react";
import { generateRandomAlphaNumeric } from "../../../Utils/alphaNumeric";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getTpScanAppDetails, postLogDetails } from "../../../Services/APIs/appDetails";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from 'yup';


const LandingPage = () => {

    const navigate = useNavigate()
    const storedUid = localStorage.getItem("usertpi")
    const initialId = storedUid ? JSON.parse(storedUid).usertpi : ''
    const [uid, setUid] = useState(initialId)
    const { images } = useSelector((state) => state.fullScan);
    const [tlStarUser, setTlStarUser] = useState(JSON.parse(localStorage.getItem("tl-star-user") || "{}"))

    // note : change bucket from toothlens to tl star

    localStorage.setItem("companyBucket", JSON.stringify({ companyBucket: "starhealth" }));
    localStorage.setItem("isAutomated", true)
    localStorage.setItem("scanType", "tl-star")

    useEffect(() => {
        // fetchAppDetails()
        !localStorage.getItem("reloaded") && reload()
        if (!uid || images.length === 0) {
            generateInfo()
        }
    }, [])

    const fetchAppDetails = async () => {
        await getTpScanAppDetails({ c: "starhealth" }).then(async (res) => {    // where c is the name of the company (third party)
            localStorage.setItem("company_style_details", JSON.stringify(res.data.data));

        }).catch((err) => {
            console.log("something went")
        });
    }

    const reload = () => {
        localStorage.setItem("reloaded", true)
        window.location.reload()
    }

    const generateInfo = () => {
        const randomString = generateRandomAlphaNumeric(2);
        const timestamp = Date.now().toString();
        const randomString2 = generateRandomAlphaNumeric(1)
        const unique = randomString + timestamp + randomString2
        localStorage.setItem("usertpi", JSON.stringify({ usertpi: unique }));
        localStorage.setItem("companyBucket", JSON.stringify({ companyBucket: "starhealth" }));
        setUid(unique)
        postLogDetails({ uid: unique, app: "starhealth", payLoad: `visited landing page` })
    }

    const openCamera = () => {
        navigate("/tl-star/scan")
    }

    const newScan = () => {
        localStorage.clear()
        localStorage.setItem("isAutomated", true)
        localStorage.setItem("reloaded", true)
        localStorage.setItem("scanType", "tl-star")
        generateInfo()
        openCamera()
    }

    const initialValues = {
        name: tlStarUser.name,
        phone_number: tlStarUser.phone_number
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        phone_number: Yup.string()
            .matches(/^[6-9]\d{9}$/, "Invalid Indian mobile number")
            .required("Phone number is required")
    })

    const handleSubmit = (values) => {
        localStorage.getItem("isScanCompleted") ? newScan() : openCamera()
        localStorage.setItem("tl-star-user", JSON.stringify(values))
    }

    return (
        <div className="h-full">
            <div className="h-2/5">
                <img
                    src={welcomeTeeth}
                    alt="welcome teeth"
                    className="saturation h-full w-full object-contain"
                />
            </div>
            <div className="h-3/5 relative flex flex-col">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {() => (
                        <Form className="flex flex-col flex-grow">
                            <div className=" flex flex-col items-center flex-grow px-5 ">
                                <h5 className=" text-2xl  font-medium text-center mb-3" >
                                    Instant Dental Health Scan
                                </h5>
                                <Field
                                    type="text"
                                    name="name"
                                    className="border-2 border-gray-400 w-full md:1/5 text-base p-2 shadow-lg rounded-md mt-5"
                                    placeholder="Enter your name here..."
                                />
                                <ErrorMessage name="name" component="div" className=" text-red-500 mt-2 w-full md:1/5" />
                                <div className="flex w-full">
                                    <Field as="select" name="country" className="border-2 border-gray-400 md:1/5 text-base p-2 shadow-lg rounded-md mt-5 bg-white">
                                        <option value="+91"> +91</option>
                                    </Field>
                                    <Field
                                        type="text"
                                        name="phone_number"
                                        className=" flex-grow ms-2 border-2 border-gray-400 md:1/5 text-base p-2 shadow-lg rounded-md mt-5"
                                        placeholder="Enter your phone number here..."
                                    />
                                </div>

                                <ErrorMessage name="phone_number" component="div" className=" text-red-500 mt-2 w-full md:1/5" />

                                <div className=" w-full flex flex-col justify-center flex-grow py-2">
                                    {
                                        localStorage.getItem("isScanCompleted") ?
                                            <div>
                                                <p className=" text-lg text-green-600 font-medium text-center my-3">You have successfully completed your scan </p>
                                                <button
                                                    type="submit"
                                                    className=" bg-black rounded text-white w-full h-11 font-semibold text-base"
                                                >
                                                    Take New Scan
                                                </button>
                                            </div>
                                            :
                                            <button
                                                type="submit"
                                                className=" bg-black rounded text-white w-full h-11 font-semibold text-base"
                                            >
                                                Scan
                                            </button>
                                    }
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                <div className="w-full px-4 mb-4">
                    <p className="flex items-center justify-center">
                        <span className="opacity-60"> Powered by </span>
                        <img
                            src={toothlensLogo}
                            alt="welcome teeth"
                            className=" ml-2 w-24"
                        />
                    </p>
                </div>
            </div>
        </div>
    )
}

export default LandingPage