import { useCallback, useEffect, useRef, useState } from "react";
import DemoScreens from "./demoScreens";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import 'react-html5-camera-photo/build/css/index.css';
import FrontCameraError from "./frontCameraError";
import Preview from "./preview";
import back from "../../../assets/Icons/Back-white.png"
import demoIcon from "../../../assets/Icons/image-reference.png"
import "./index.css"
import PermissionPopup from "./cameraPermissionPopup";
import { postLogDetails } from "../../../Services/APIs/appDetails";
import teethSides from "../../../Utils/teethSideNames";
import { notifyCameraError } from "../../../Services/APIs/scan";
import { GetMobileOperatingSystem } from "../../../Utils/getOS";
import { GetBrowserDetails } from "../../../Utils/deviceDetails";
import { getCompleteDeviceDetails } from "../../../Utils/comepleteDeviceInfo";
import { useTranslation } from "react-i18next";


const Shoot = (props) => {
    const { t, i18n } = useTranslation("demoscreen")
    const steps = t("content", { returnObjects: true })
    const { activeImageSide, setImageSide, setToothPicker } = props
    const [facialMode, setFacialMode] = useState(FACING_MODES.USER)
    const [frontCameraError, setFrontCameraError] = useState(false)
    const [isDemo, setDemo] = useState(true)
    const [isCamera, setCamera] = useState(true)
    const [capturedImage, setCapturedImage] = useState()
    const [mediaStream, setStream] = useState(null)
    const OS = GetMobileOperatingSystem()   // operating system
    const [errorType, setErrorType] = useState({ isError: false, type: "ios" })
    const uid = JSON.parse(localStorage.getItem("usertpi") || '{}').usertpi
    const app = `perigum-${i18n.language}`


    const cameraStarted = (stream) => {
        setStream(stream)
        handleCameraType()
        setErrorType({ isError: false, type: "ios" })
    }

    const handleCameraType = () => {
        OS === "iOS" ? handleWithFacialMode() : handleWithLabel()
    }

    const handleWithFacialMode = () => {
        navigator.mediaDevices.getUserMedia({ video: { facingMode: FACING_MODES.USER } })
            .then((stream) => {
                setFacialMode(FACING_MODES.USER)
                postLogDetails({ uid, app, payLoad: `iOS - found front camera, video-track-length-${stream.getVideoTracks().length}` })
            })
            .catch((error) => {
                postLogDetails({ uid, app, payLoad: `ios- front camera error  ${JSON.stringify(error)} so starting rear-camera` })
                notifyCameraError({ info: { ...getCompleteDeviceDetails(), uid, app, error: JSON.stringify(error) } })
                setFrontCameraError(true)
                setFacialMode(FACING_MODES.ENVIRONMENT)
            });
    }

    const handleWithLabel = () => {
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                const videoDevices = devices.filter(device => device.kind === 'videoinput');
                const frontCamera = videoDevices.find(device => device.getCapabilities().facingMode?.includes("user"));
                if (frontCamera) {
                    setFacialMode(frontCamera.deviceId); // Set the deviceId directly
                    postLogDetails({ uid, app, payLoad: "Android - found front camera" })

                } else {
                    postLogDetails({ uid, app, payLoad: `Android- front camera error  ${JSON.stringify(videoDevices)} so starting rear-camera` })
                    notifyCameraError({ info: { ...getCompleteDeviceDetails(), uid, app, error: JSON.stringify(videoDevices) } })
                    setFacialMode(FACING_MODES.ENVIRONMENT); // Fallback to rear camera
                    setFrontCameraError(true)
                }
            })
            .catch(err => {
                postLogDetails({ uid, app, payLoad: `Android- media devices error ${JSON.stringify(err)}` })
            });
    }


    const handleTakePhoto = (dataUri) => {
        const base64String = dataUri.split(',')[1]; // Remove the data URI scheme part
        const base64StringLength = base64String.length;
        const estimatedSizeInBytes = (base64StringLength * 3) / 4; // Base64 size formula
        const estimatedSizeInKB = estimatedSizeInBytes / 1024; // Convert to KB
        const estimatedSizeInMB = estimatedSizeInKB / 1024; // Convert to MB
        setCapturedImage(dataUri)
        postLogDetails({
            uid: JSON.parse(localStorage.getItem("usertpi")).usertpi,
            app: JSON.parse(localStorage.getItem("companyBucket")).companyBucket + `-${i18n.language}`,
            payLoad: `captured ${teethSides[activeImageSide]} teeth, ${estimatedSizeInMB.toFixed(5)} MB`,
        })
        setTimeout(() => {
            setCamera(false)
        }, 100)
        stopCamera();
    }

    const OvuleShape = () => {
        return (
            <div className={`ovule-container w-full flex flex-col justify-center items-center `}>
                <div className={`relative peri-ovule ovule-${activeImageSide} ${activeImageSide === 4 && isCamera && "ovule-4-reversed"}  `}>
                </div>
            </div>
        )
    }

    const TopLayer = () => {
        return (
            <div className='camera-top-layer-2 h-full absolute top-0 text-white w-full'>
                <div className='flex justify-between items-center h-13 px-2'>
                    <button type='button' onClick={() => { localStorage.getItem("isAutomated") ? window.history.back() : setToothPicker(true) }}> <img src={back} alt="back" className="w-8 h-8" /> </button>
                    <div className='pt-2'>
                        <button type='button' onClick={() => { setDemo(true) }} > <img src={demoIcon} alt="back" className="w-12 h-12 " /> </button>
                    </div>
                </div>
                <p
                    className={`${isCamera ? " text-3xl" : "text-base lowercase"} font-cera-pro-medium mt-16 text-center ${activeImageSide === 4 && isCamera && "ransform rotate-180 "}`}
                >
                    {isCamera ? <>
                        {steps[activeImageSide].title.split("\n").map((line, index) => (
                            <span key={index}>
                                {line}
                                <br />
                            </span>
                        ))}
                    </> : `${t("previewTeeth")} ${steps[activeImageSide].title}`.replace("Bước", "")}

                </p>
                {isCamera && <p className=" font-cera-pro-medium text-center text-base w-full absolute bottom-1/4"> {t("tapToCapture")} </p>}
            </div>
        )
    }
    const checkError = () => {
        const browser = GetBrowserDetails();

        if (browser.name === "Mobile Chrome") {
            navigator.permissions.query({ name: 'camera' }).then((res) => {
                if (res.state === "denied") {
                    if (OS === 'iOS') {
                        setErrorType({ isError: true, type: "ios" })
                    } else {
                        setErrorType({ isError: true, type: "android" })
                    }
                }
            }).catch((error) => {
                setErrorType({ isError: true, type: "ios" })
            })

        } else {
            setErrorType({ isError: true, type: "ios" })
        }

    }

    const stopCamera = () => {
        // console.log("Tracks before stopping:", mediaStream.getTracks()); // Log tracks
        if (mediaStream) {
            mediaStream.getTracks().forEach((track) => {
                // console.log(`Stopping track with kind: ${track.kind}, readyState: ${track.readyState}`);
                postLogDetails({ uid, app, payLoad: `Stopping track with kind: ${track.kind}, readyState: ${track.readyState}` })
                track.stop()
                // console.log(`Track stopped. New readyState: ${track.readyState}`);
                postLogDetails({ uid, app, payLoad: `Track stopped ${track.kind}, readyState: ${track.readyState}` })
            });
            setStream(null);
        }
    };

    return (
        <div className="h-full w-full">
            {
                isDemo ? <DemoScreens activeImageSide={activeImageSide} setDemo={setDemo} setCamera={setCamera} /> :
                    <div className="h-full w-full">
                        {errorType.isError && <PermissionPopup info={errorType} setErrorType={setErrorType} />}

                        {isCamera ?
                            <div className='h-full relative'>
                                <Camera
                                    onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                                    idealFacingMode={facialMode} // Set back camera preference
                                    isFullscreen={false}
                                    isMaxResolution={true}
                                    imageType={IMAGE_TYPES.JPG}
                                    imageCompression={0.9}
                                    sizeFactor={1}
                                    videoConstraints={{
                                        facingMode: { exact: "user" }, // Force front camera
                                        width: { ideal: window.innerWidth },
                                        height: { ideal: window.innerHeight },
                                        aspectRatio: { ideal: window.innerWidth / window.innerHeight }
                                    }}
                                    isImageMirror={facialMode !== FACING_MODES.ENVIRONMENT}
                                    onCameraStart={cameraStarted}
                                    onCameraError={checkError}
                                    className="WebCam"
                                />
                                {!errorType.isError && frontCameraError && <FrontCameraError back={setFrontCameraError} />}

                            </div> :
                            <Preview
                                setImageSide={setImageSide}
                                image={capturedImage}
                                setCamera={setCamera}
                                setToothPicker={setToothPicker}
                                activeImageSide={activeImageSide}
                                setDemo={setDemo} />
                        }
                        <>
                            <OvuleShape />
                            <TopLayer />
                        </>


                    </div>
            }
        </div>
    )
}

export default Shoot