import error from "../../../assets/svg/failed.svg";
import close from "../../../assets/Icons/close.png";
import { useNavigate } from "react-router-dom";


const ErrorUI = (props) => {
    const { setError } = props
    const navigate = useNavigate()
    return (
        <div className="h-full absolute top-0 z-10 w-full flex justify-center items-center bg-black/[0.3] backdrop-blur-sm">
            <div className="bg-white w-3/4 flex flex-col items-center p-5 rounded-md shadow-sm">
                <div className="text-end w-full">


                    <button
                        type="button"
                        onClick={() => { setError(false) }}>
                        <img src={close} className="w-6 h-6" alt="close" />
                    </button>

                </div>
                <img src={error} alt="error" className=" w-40 h-40" />
                <p className="text-center my-5 font-medium">
                Xin lỗi!! AI đang gặp khó khăn. Vui lòng thử lại sau giây lát.
                </p>

                <button
                    type="button"
                    className="bg-uv-dark-blue text-white w-full h-11 font-semibold text-base shadow-2xl"
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                     Trang tổng quan
                </button>

            </div>
        </div>
    );
};

export default ErrorUI