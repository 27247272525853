import { useState } from "react";
import { useNavigate } from "react-router-dom";
import pepsodent_logo from "../../../assets/unilever/Pepsodent Logo.png";
import close from "../../../assets/Icons/close.png"
import { sendFileToWhatsapp } from "../../../Services/APIs/report";
import toothlensLogo from "../../../assets/illustrations/Toothlens_logo.png";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const IPadThanks = () => {
    const [DownloadStatus, setDownloadStatus] = useState("Kirim laporan ke WhatsApp");
    const navigate = useNavigate()
    const pdf_url = localStorage.getItem("pdf_url")


    const initialValues = {
        phone_number: ""

    }

    const validationSchema = Yup.object().shape({
        phone_number: Yup.string()
            .matches(/^\d+$/, "Hanya angka yang diperbolehkan")
            .required("Nomor telepon diperlukan")
    });

    const handleSubmit = async (values) => {
        if (pdf_url) {
            setDownloadStatus("Berbagi...")
            await sendFileToWhatsapp({ ph_no: values.phone_number, file: pdf_url, company: "unilever" }).then((res) => {
                setDownloadStatus("Berhasil dibagikan")
                setTimeout(() => {
                    setDownloadStatus("Kirim laporan ke WhatsApp")
                }, 2500)
            }).catch((err) => {
                setDownloadStatus("Gagal")
                setTimeout(() => {
                    setDownloadStatus("Kirim laporan ke WhatsApp")
                }, 2500)
            })
        } else {
            setDownloadStatus("Gagal")
            setTimeout(() => {
                setDownloadStatus("Kirim laporan ke WhatsApp")
            }, 2500)

        }

    }


    return (
        <div className="h-full bg-custom-gradient text-center flex flex-col  items-center px-3">
            <div className="absolute top-0 right-0 p-3">
                <button type="button"
                    onClick={() => { navigate("/ipad") }} >
                    <img src={close} alt="close_img" className=" w-7 md:w-11" />

                </button>
            </div>


            <div className=" flex-grow w-full text-4xl text-blue-900 font-semibold flex flex-col items-center justify-center mt-5">
                <div className=" mt-20 flex justify-center">
                    <img src={pepsodent_logo} alt="done" className="w-3/4" />
                </div>

                <p
                    style={{
                        color: "#2C3E50",
                        fontFamily: "Poppins",
                        fontWeight: "700",
                    }}
                    className="text-center mt-2 text-base md:text-3xl "
                >
                    Analisa dasar kesehatan gigi kamu sudah siap
                </p>
                <p className="mt-10">Terima kasih</p>
            </div>

            <p
                className="text-center mt-3 font-base md:text-xl "
                style={{
                    color: "#2C3E50",
                    fontFamily: "Poppins",
                }}
            >
                Konsultasikan dengan dokter gigi untuk mendapatkan rekomendasi perawatan
            </p>
            <div className=" w-full px-4 my-5">

                <p className="flex items-center justify-center">
                    <span className="opacity-60 md:text-lg"> Powered by </span>

                    <img
                        src={toothlensLogo}
                        alt="welcome teeth"
                        className=" ml-2 w-24 md:w-32"
                    />
                </p>

            </div>
        </div>
    )
}

export default IPadThanks






