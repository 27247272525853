export const vietnam_overall_score = (props) => {
    const { gaps_poorly_aligned_teeth, gum_recession, gum_swelling, tartar_score, cavity_score } = props

    const alignment_score = gaps_poorly_aligned_teeth === true ? 0 : 20
    let recession = 0
    let swelling = 0
    let tartar = 0
    let cavity = 0

    if (gum_swelling === 0) {
        swelling = 20
    } else if (gum_swelling >= 1 && gum_swelling <= 5) {
        swelling = 10
    } else {
        swelling = 0
    }

    if (gum_recession === 0) {
        recession = 20
    } else if (gum_recession >= 1 && gum_recession <= 5) {
        recession = 10
    } else {
        recession = 0
    }

    if (tartar_score === 0) {
        tartar = 20
    } else if (tartar_score >= 1 && tartar_score <= 5) {
        tartar = 10
    } else {
        tartar = 0
    }

    if (cavity_score === 0) {
        cavity = 20;
    } else {
        cavity = 0;
    }

    const percentage = ((recession + swelling + tartar + cavity) / 80) * 100
    return parseInt(percentage)
}