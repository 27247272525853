import { useEffect } from "react"
import { postLogDetails } from "../../../Services/APIs/appDetails"

const PermissionPopup = (props) => {
    
    useEffect(()=>{
        postLogDetails({
            uid: JSON.parse(localStorage.getItem("usertpi")).usertpi,
            app: JSON.parse(localStorage.getItem("companyBucket")).companyBucket,
            payLoad: "camera permission denied"
        })        
    }, [])

    return (
        <div className="absolute top-0 camera-error-popup bg-black/[0.3] backdrop-blur-sm h-full w-full flex justify-center items-center px-6">
            <div className=" flex flex-col bg-white p-5 rounded">
                <p className=" text-2xl font-bold text-center py-3">INFO</p>
                <p className="pb-5">   Có vẻ như bạn đã tắt quyền truy cập camera. Vuốt màn hình xuống và chấp nhận quyền truy cập camera để tiếp tục. Bạn cũng có thể vào cài đặt và bật quyền truy cập camera.</p>
                <p className=" font-normal text-sm text-black">
                    <span className=""> Cần giúp đỡ? Ghé thăm </span>
                    <span className="font-bold pl-2">
                        <a href="mailto:support@toothlens.com"> Trung tâm trợ giúp </a>
                    </span>
                </p>
                <button
                    type="button"
                    onClick={() => window.history.back()}
                    className="bg-black text-white px-5 h-11 font-semibold text-base rounded self-end">Ok</button>
            </div>
        </div>
    )
}

export default PermissionPopup