import React, { useState } from "react";
import { downloadPdf, downloadPdfForBearn } from "../../Services/APIs/report";
import { postBookAppointment } from "../../Services/APIs/annotation";
import {
    fontStyle,
    buttonStyle,
    fontWeight,
    companyBucket,
} from "../../Utils/tpscanStyle";

const DownloadPdf = () => {
    const [DownloadStatus, setDownloadStatus] = useState("Download My Report");
    const [bookAppointmentStatus, setBookAppointmentStatus] = useState(
        "Book An Appointment"
    );

    const uid = JSON.parse(localStorage.getItem("usertpi")).usertpi


    const download = async () => {
        setDownloadStatus("Downloading...");
        const file = localStorage.getItem("pdf");
        if (file) {
            const company = JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket;
            await downloadPdf({ file, company, uid })
                .then((response) => {
                    const url = response.data?.data?.info;
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = file; // Optional: Specify a download attribute
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setDownloadStatus("Downloaded");
                    setTimeout(() => {
                        setDownloadStatus("Download My Report");
                    }, 2500);
                })
                .catch((error) => {
                    setDownloadStatus("Failed, try again...");
                    setTimeout(() => {
                        setDownloadStatus("Download My Report");
                    }, 2500);
                });
        } else {
            setDownloadStatus("Not Found");
        }
    };
    const bookAppointment = async () => {
        setBookAppointmentStatus("Loading...");
        await postBookAppointment()
            .then((res) => {
                setTimeout(() => {
                    setBookAppointmentStatus("Redirecting to book an appoitment");
                }, 2500);
                setBookAppointmentStatus("Book An Appointment");
            })
            .catch((err) => {
                setBookAppointmentStatus("Book An Appointment");
            });
    };

    const goTovietnam = () => {
        window.location.href = `https://psaicheck.quatangunilever.vn/?transactionId=${encodeURIComponent(uid)}`  
    }
    return (
        <div>
            <div className="  w-full px-5 pb-3  rounded ">
                <button
                    type="button"
                    className="  bg-uv-dark-blue text-white w-full h-11 font-semibold text-base rounded mt-2"
                    onClick={goTovietnam}
                >
                    Tải xuống báo cáo của tôi
                </button>
            </div>
            <div className="mx-5 py-3 text-center tracking-wide opacity-50"
                style={{ ...fontStyle, fontSize: "12px" }}>
                Vui lòng lưu ý rằng những đánh giá này không thể thay thế cho ý kiến của chuyên gia y tế. Chúng được thiết kế để nâng cao nhận thức về sức khỏe răng miệng của bạn                       </div>
        </div>
    );
};

export default DownloadPdf;