import React, { useEffect, useState } from "react";
import CompanyLogo from '../../../assets/vietnam/logo.png';
import BottomImages from '../../../assets/vietnam/developed-with.png';
import '../style/peri-check.css'
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { generateRandomAlphaNumeric } from "../../../Utils/alphaNumeric";
import { postLogDetails } from "../../../Services/APIs/appDetails";
import { useTranslation } from "react-i18next";

const LandingPage1 = () => {

    const { language } = useParams()
    const { t, i18n } = useTranslation("l1")

    useEffect(() => {
        const availableVersions = ["en", "vn", "bs"]

        if (availableVersions.includes(language)) {
            localStorage.setItem("userLanguage", language);
            i18n.changeLanguage(language)
            return
        } else {
            i18n.changeLanguage("en")
            localStorage.setItem("userLanguage", "en");

        }

    }, [])

    const navigate = useNavigate()
    const storedUid = localStorage.getItem("usertpi")
    const initialId = storedUid ? JSON.parse(storedUid).usertpi : ''
    const [uid, setUid] = useState(initialId)
    const { images } = useSelector((state) => state.fullScan);

    localStorage.setItem("companyBucket", JSON.stringify({ companyBucket: "perigum" }));
    localStorage.setItem("isAutomated", true)
    localStorage.removeItem("scanType");

    useEffect(() => {
        setTimeout(() => {
            moveToPage2()
        }, 3500);
        !localStorage.getItem("reloaded") && reload()
        if (!uid || images.length === 0) {
            generateInfo()
        }
    }, [])

    const reload = () => {
        localStorage.setItem("reloaded", true)
        window.location.reload()
    }

    const generateInfo = () => {
        const randomString = generateRandomAlphaNumeric(2);
        const timestamp = Date.now().toString();
        const randomString2 = generateRandomAlphaNumeric(1)
        const unique = randomString + timestamp + randomString2
        localStorage.setItem("usertpi", JSON.stringify({ usertpi: unique }));
        localStorage.setItem("companyBucket", JSON.stringify({ companyBucket: "perigum" }));
        setUid(unique)
        postLogDetails({ uid: unique, app: `perigum-${i18n.language}`, payLoad: `visited landing page` })
    }

    const moveToPage2 = () => {
        localStorage.removeItem("isScanCompleted")
        navigate("/peri-gum/landing-page-1")
    }

    const newScan = () => {
        localStorage.clear()
        localStorage.setItem("isAutomated", true)
        localStorage.setItem("reloaded", true)
        generateInfo()
        moveToPage2()
    }


    return (
        <div className="relative w-full h-full bg-cover bg-center landing-page1-container">

            <div className="flex items-center justify-center h-full text-left text-white flex-col">
                {/* Centered welcome text */}
                <div>
                    <h1 className=" text-6xl font-cera-pro-black mb-6 text-peri-primary leading-[52px]">
                        {t("header").split("\n").map((line, index) => (
                            <span key={index}>
                                {line}
                                <br />
                            </span>
                        ))}
                    </h1>
                </div>
            </div>

            <div className="flex flex-col w-full h-[25%] items-center justify-between absolute bottom-4 left-1/2 transform -translate-x-1/2">

                <div>
                    {/* Logo */}
                    <img
                        src={CompanyLogo}
                        alt="Logo"
                    />
                </div>

                {/* Bottom multiple company logos */}
                <div className="flex justify-between space-x-6 flex-col text-black">
                    <p className="text-center mb-2 text-white font-cera-pro-light"> {t("footer")} </p>
                    <img
                        src={BottomImages}
                        alt="company logos"
                    />
                </div>
            </div>
        </div>
    );
};

export default LandingPage1;
