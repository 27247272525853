import { useNavigate } from 'react-router-dom'
import DownloadIcon from '../../../assets/periGum/download-Icon.png'
import { gumHealthAnalysis } from '../../../Utils/periGumHealthAnalysis'
import React, { useState } from 'react'
import { downloadPdf } from '../../../Services/APIs/report'
import { useTranslation } from "react-i18next";

const GumHealthSummary = () => {
    const { t } = useTranslation("gum_health")
    const riskactorsList = t("factors", { returnObjects: true })
    const navigate = useNavigate()
    const tpscore = JSON.parse(localStorage.getItem("tpscore") || "{}")
    const { tartar_score, gum_swelling, gum_recession } = tpscore
    const user_info = JSON.parse(localStorage.getItem("peri-user-questionnaire") || "{}")

    const getAnalysis = gumHealthAnalysis({ tpscore, user_info, riskactorsList })
    const { final_result, final_risk_factor } = getAnalysis
    localStorage.setItem("peri-report", final_result)

    const uid = JSON.parse(localStorage.getItem("usertpi") || "{}").usertpi
    const language = localStorage.getItem("userLanguage") || "en";
    const initialDownloadText = language === "vn" ? "Tải về báo cáo chi tiết" :
        language === "bs" ? "Laporan lengkap\n disini" :
            "Download full report\n here"; // Default to English

    const [downloadText, setDownloadStatus] = useState(initialDownloadText);

    const download = async () => {
        setDownloadStatus(language === "vn" ? "Đang tải xuống..." :
            language === "bs" ? "mengunduh..." :
                "Downloading...");
        const file = localStorage.getItem("pdf");
        if (file) {
            const company = JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket;
            await downloadPdf({ file, company, uid })
                .then((response) => {
                    const url = response.data?.data?.info;
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = file; // Optional: Specify a download attribute
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setDownloadStatus(language === "vn" ? "Tải xuống thành công" :
                        language === "bs" ? "Berhasil diunduh" :
                            "Download Successful");

                    setTimeout(() => {
                        setDownloadStatus(initialDownloadText);
                    }, 2500);
                })
                .catch((error) => {
                    setDownloadStatus(language === "vn" ? "Thất bại, thử lại..." :
                        language === "bs" ? "Gagal, coba lagi..." :
                            "Failed, try again...");
                    setTimeout(() => {
                        setDownloadStatus(initialDownloadText);
                    }, 2500);
                });
        } else {
            setDownloadStatus(language === "vn" ? "Có gì đó sai.\n Vui lòng thử lại sau" :
                language === "bs" ? "Ada yang salah.\n Silakan coba lagi nanti" :
                    "Something went wrong.\n Please try again later");
        }
    };

    return (
        <div className=" mt-5 font-cera-pro-medium font-semibold">
            <div>
                <p className="text-2xl text-peri-blue">{t("plaque-tartar-buildup")}</p>
                <div className="flex bg-white rounded-full  text-lg mt-4">
                    <p className={`flex-grow text-center py-3 rounded-full ${tartar_score === 0 ? "bg-peri-blue text-white" : "bg-white text-[#7C7F81]"}`}>{t("no")} </p>
                    <p className={`flex-grow text-center py-3 rounded-full ${tartar_score === 0 ? "bg-white text-[#7C7F81]" : "bg-peri-blue text-white"}`}>{t("yes")}</p>
                </div>
                <p className="text-[#B2B2B2] mt-2 font-normal">
                    {t("tartar-insights")}                </p>
            </div>
            <div className="mt-5">
                <p className=" text-2xl text-peri-green">{t("gum-recession")}</p>
                <div className="flex bg-white rounded-full  text-lg mt-4">
                    <p className={`flex-grow text-center py-3 rounded-full ${gum_recession === 0 ? "bg-peri-green text-white" : "bg-white text-[#7C7F81]"}`}>{t("no")}</p>
                    <p className={`flex-grow text-center py-3 rounded-full ${gum_recession === 0 ? "bg-white text-[#7C7F81]" : "bg-peri-green text-white"}`}>{t("yes")}</p>
                </div>
                <p className="text-[#B2B2B2] mt-2 font-normal">
                    {t("gum-insights")}                </p>
            </div>
            <div className="mt-5">
                <p className="  text-2xl text-peri-pink">{t("gum-swelling")}</p>
                <div className="flex bg-white rounded-full  text-lg mt-4">
                    <p className={`flex-grow text-center py-3 rounded-full ${gum_swelling === 0 ? "bg-peri-pink text-white" : "bg-white text-[#7C7F81]"}`}>{t("no")}</p>
                    <p className={`flex-grow text-center py-3 rounded-full ${gum_swelling === 0 ? "bg-white text-[#7C7F81]" : "bg-peri-pink text-white"}`}>{t("yes")}</p>
                </div>
                <p className="text-[#B2B2B2] mt-2 font-normal">
                    {t("gum-swelling-insights")}                </p>
            </div>

            <div className='mt-5'>
                <p className="text-2xl text-peri-blue py-5">{t("risk-factor")}: {final_risk_factor}</p>
                <hr />
                <div className='mt-8 text-center pb-8'>
                    <p className={`text-[20px] bg-white p-3 font-[700] rounded-full ${final_result === "healthy gums" ? " peri-active" : ""} text-[#7C7F81]`}>{t("healthy-gums")} </p>
                    <p className={`text-[20px] bg-white p-3 font-[700] rounded-full ${final_result === "at risk of gingivitis" ? " peri-active" : "text-[#7C7F81]"} text-[#7C7F81] my-2`}>{t("at-risk-of-ginguitis")}</p>
                    <p className={`text-[20px] bg-white p-3 font-[700] rounded-full ${final_result === "gingivitis" ? " peri-active" : "text-[#7C7F81]"} my-2`}>{t("gingivitis")}</p>
                    <p className={`text-[20px] bg-white p-3 font-[700] rounded-full ${final_result === "advanced gum disease" ? " peri-active" : "text-[#7C7F81]"} text-[#7C7F81] my-2`}>{t("advanced-gum-disease")}</p>
                </div>
                <hr />
                <div className='text-center mt-10 pb-10'>
                    <button type="button" className='' onClick={download}>
                        <img className='text-center inline-block' src={DownloadIcon} alt='download icon' />
                    </button>
                    <p style={{ whiteSpace: "pre-line" }}>{downloadText}</p>
                </div>
                <div className="flex justify-center w-full">
                    <button
                        className=" font-cera-pro-medium font-semibold  mt-6 border border-[#7C7F81] text-peri-blue text-center rounded-lg text-[24px] mb-5"
                        style={{ width: '159px', height: '50px', borderRadius: '25px' }} // Width: 159px, Height: 50px
                        onClick={() => { navigate("/peri-gum/health-report") }}
                    >
                        {t("submit-button")}
                    </button>
                </div>
            </div>


        </div>
    )
}

export default GumHealthSummary