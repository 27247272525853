import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const getCompany = () => {
    const compnay = JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket
    return compnay
}

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: () => `/locales/${getCompany()}/{{lng}}/{{ns}}.json`,
        },
    });

export default i18n;
