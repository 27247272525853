import React, { useEffect, useRef, useState } from "react";
import ImagePlaceholder from "./image-placeholder1.svg";
import "./annotation.css";
import teethAnimation from "../../assets/animations/Animation - bearn.json";
import Lottie from "lottie-react";

const Annotation = (props) => {
    let imageTagsData = [];
    const [activeImage, setActiveImage] = useState(props?.image?.imageUrl);
    const imgRef = useRef();
    const [filteredImageTags, setFilteredImageTags] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const dd = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${yyyy}-${mm}-${dd}`;

    useEffect(() => {
        setActiveImage(props?.image?.imageUrl);
        if (!props.texTags && props.jsonTags && imgRef.current) {
            jsonTags();
        } else if (props.texTags && imgRef.current) {
            getTxtTags();
        }
    }, [props, loaded]);

    const jsonTags = () => {
        let tagsData = props.jsonTags;
        const GetShortName = (name, splitOption) => {
            return (
                name &&
                `${name
                    .split(splitOption)
                    .map((item) => item[0].toUpperCase())
                    .join("")}`
            );
        };
        const TAGS_COLOR_CODE = {
            C: null,
            C1: null,
            C2: null,
            C3: null,
            T: "#0C01DB",
            E: null,
            GS: null,
            GR: null,
            BT: null,
        };
        if (props.companyS3Bucket === "unileverviet-image-store") {
            TAGS_COLOR_CODE["C"] = "#0099FF"
            TAGS_COLOR_CODE["C1"] = "#0099FF"
            TAGS_COLOR_CODE["C2"] = "#0099FF"
            TAGS_COLOR_CODE["C3"] = "#0099FF"
            TAGS_COLOR_CODE["T"] = "#0C01DB"
            TAGS_COLOR_CODE["GS"] = "#FF0A88"
            TAGS_COLOR_CODE["GR"] = "#D809EA"
        }
        const imageTags = [];
        const imgNaturalWidth = imgRef.current.naturalWidth;
        const imgNaturalHeight = imgRef.current.naturalHeight;
        const renderImageWidth = imgRef.current.width;
        const renderImageHeight = imgRef.current.height;
        const coordinatesData = [];
        for (let key in tagsData) {
            if (
                Array.isArray(tagsData[key]) &&
                key !== "missing_teeth" &&
                key !== "broken_teeth" &&
                key !== "gaps"  
            ) {
                tagsData[key].forEach((item) => {
                    let { x, y, w, h } = item;
                    x = (x * renderImageWidth) / imgNaturalWidth;
                    y = (y * renderImageHeight) / imgNaturalHeight;
                    w = (w * renderImageWidth) / imgNaturalWidth;
                    h = (h * renderImageHeight) / imgNaturalHeight;
                    coordinatesData.push({ x, y, width: w, height: h });
                    const shortName = GetShortName(key, "_");
                    const colorCode = TAGS_COLOR_CODE[shortName];
                    imageTags.push({
                        name: shortName,
                        style: {
                            left: `${x}px`,
                            top: `${y}px`,
                            width: `${w}px`,
                            height: `${h}px`,
                            border: `2px solid ${colorCode}`,
                            background: "transparent",
                            // outline: shortName != "G" ? "2px solid white" : "",
                        },

                    });
                });
            }
        }
        setFilteredImageTags(imageTags);
    };
    const getTxtTags = () => {
        if (props.texTags != null) {
            const imageTags = [];
            let coordinates = [];
            const imgWidth = imgRef?.current?.width;
            const imgHeight = imgRef?.current?.height;
            let tartar = 0;
            let cavity = 0;
            const cavityCall = () => {
                return (cavity = cavity + 1);
            };
            const tartarCall = () => {
                return (tartar = tartar + 1);
            };
            const tagsData = props.texTags;
            tagsData.forEach((data, index) => {
                const splitCoordinates = data.split(" ").slice(1, 5);
                if (splitCoordinates.length > 1) {
                    const { x, y, w, h } = {
                        x: Number(splitCoordinates[0]),
                        y: Number(splitCoordinates[1]),
                        w: Number(splitCoordinates[2]),
                        h: Number(splitCoordinates[3]),
                    };
                    const { x_min, x_max, y_min, y_max } = getTagsCoordinates(
                        { x, y, w, h },
                        imgWidth,
                        imgHeight
                    );
                    coordinates.push({
                        x: x_min,
                        y: y_min,
                        width: x_max - x_min,
                        height: y_max - y_min,
                    });
                    const colorCode =
                        data.split(" ")[0] == 0
                            ? "#0000FF"
                            : data.split(" ")[0] == 1
                                ? "#FF0000"
                                : data.split(" ")[0] == 2
                                    ? "#68a8a8"
                                    : data.split(" ")[0] == 3
                                        ? "#68a8a8"
                                        : "#0000FF";
                    data.split(" ")[0] ? tartarCall() : cavityCall();
                    imageTags.push({
                        name: data.split(" ")[0] ? "T" : "C",
                        style: {
                            left: `${x_min}px`,
                            top: `${y_min}px`,
                            width: `${x_max - x_min}px`,
                            height: `${y_max - y_min}px`,
                            border: `2px solid ${colorCode}`,
                            background: "transparent",
                            // outline: "2px solid white",
                        }
                    });
                }
            });
            setFilteredImageTags(imageTags);
            imageTagsData = coordinates;

            function getTagsCoordinates({ x, y, w, h }, imgWidth, imgHeight) {
                let x_min = (x - w / 2) * imgWidth;
                let x_max = (x + w / 2) * imgWidth;
                let y_min = (y - h / 2) * imgHeight;
                let y_max = (y + h / 2) * imgHeight;

                return {
                    x_min,
                    x_max,
                    y_min,
                    y_max,
                };
            }
        }
    };

    return (
        <div className="relative">
            <img
                src={activeImage}
                ref={imgRef}
                alt="report"
                className={`${props.companyS3Bucket === "unileverviet-image-store" ? "max-h-72" : "w-full"}`}
                onLoad={() => setLoaded(true)}
            />

            {filteredImageTags.map((item, index) => {
                return (
                    <div key={index}>
                        {imgRef.current && <div
                            key={index}
                            className="popup-annotation"
                            style={{ ...item.style }}
                        ></div>}
                    </div>
                );
            })}
            {props.companyS3Bucket !== "unileverviet-image-store" && <p className="text-lg date">{formattedDate}</p>}

        </div>
    );
};
export default Annotation;
