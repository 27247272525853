import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PeriButton from "../Components/Button";
import '../style/peri-check.css'
import { useTranslation } from "react-i18next";

const LandingPage2 = () => {
    const { t } = useTranslation("l2")

    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/peri-gum/landing-page-2')
        }, 3500)
    })

    return (
        <div className="relative w-full h-full landing-page2-container">

            <div className="flex justify-center h-full text-white flex-col m-auto w-[75%]">
                {/* Centered welcome text */}
                <div>
                    <h1 className="text-[55px] font-cera-pro-black mb-6 text-peri-secondary leading-[45px]">
                        {t("header").split("\n").map((line, index) => (
                            <span key={index}>
                                {line}
                                <br />
                            </span>
                        ))}
                    </h1>
                </div>

                <div className="mt-4 w-full">
                    <PeriButton text={t("callToAction")} />
                </div>
                <p className="text-peri-secondary font-cera-pro-medium text-[14px] mt-10 font-[700]"> {t("footer")}</p>

            </div>
        </div>
    );
};

export default LandingPage2;
