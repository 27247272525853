import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import close from "../../assets/Icons/close-white.png"

import steps from "./steps";
import { fontWeight, fontStyle, companyBucket, company_style_details, buttonStyle } from "../../Utils/tpscanStyle"
import UpperJawDemonstartion from "./upperJawDemonstration";
import { postLogDetails } from "../../Services/APIs/appDetails";
import teethSides from "../../Utils/teethSideNames";
import FaceDemoScreen from "../StarInsurance/Scan/face";

const DemoScreens = (props) => {
    const { dentalOfficeName } = useParams();
    const [isUpperJawDemonstration, setUpperJawDemonstration] = useState(false)
    const { activeImageSide, setToothPicker, setDemo, setCamera, quickScan } = props
    const navigate = useNavigate()

    postLogDetails({
        uid: JSON.parse(localStorage.getItem("usertpi") || "{}").usertpi,
        app: JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket,
        payLoad: `viewed ${teethSides[activeImageSide]} teeth demo screen` 
    })
    const doneGuide = [
        "Rotate and hold your phone so the selfie camera is just below your chin",
        "Fit your teeth within the template"
    ]

    const gotIt = () => {
        setDemo(false)
        setCamera(true)
    }

    return (
        <>

            { activeImageSide === -1 ? <FaceDemoScreen gotIt={gotIt} back={setUpperJawDemonstration} /> :
                isUpperJawDemonstration ? <UpperJawDemonstartion gotIt={gotIt} back={setUpperJawDemonstration} /> :
                    <div className="h-full w-full">
                        <div className="z-20 relative h-full w-full text-end text-white ">
                            <button className="p-1" onClick={() => { localStorage.getItem("isAutomated") ? window.history.back() : setToothPicker(true) }}> <img src={close} className="w-7 h-7 m-2" alt="close" /> </button>
                            <h1 className=" text-center text-2xl font-semibold mt-5" style={{ ...fontWeight, ...fontStyle }}>{steps[activeImageSide].title}</h1>
                            <div className="w-full flex flex-col items-center absolute bottom-8 text-center">
                                {
                                    activeImageSide === 4 ?
                                        <div>
                                            {
                                                (companyBucket === "done" || companyBucket === "hascompanies") &&
                                                <ul className=" list-outside text-white text-start me-5 ms-14 mb-7 list-disc" style={(companyBucket == "done" || companyBucket === "hascompanies") ? { ...fontStyle } : { ...fontWeight, ...fontStyle }}>
                                                    {
                                                        doneGuide.map((tip, i) => <li key={i} className="mb-2"> {tip} </li>)
                                                    }
                                                </ul>
                                            }
                                            <button
                                                className="text-center h-12 w-44 bg-white text-black font-semibold rounded mb-3"
                                                onClick={() => { setUpperJawDemonstration(true) }}
                                                style={(companyBucket == "done" || companyBucket === "hascompanies") ? { ...buttonStyle, ...fontStyle, borderRadius: "30px", height: "auto", padding: "10px 40px", background: "white" } : {}}>
                                                <span style={(companyBucket == "done" || companyBucket === "hascompanies") ? { ...fontStyle, color: "black", fontSize: "16px" } : { ...fontStyle }}>View steps</span>
                                            </button>
                                        </div>
                                        :
                                        <ul className=" list-outside text-white text-start me-5 ms-14 mb-10 list-disc" style={(companyBucket == "done" || companyBucket === "hascompanies") ? { ...fontStyle } : { ...fontWeight, ...fontStyle }}>
                                            {
                                                steps[activeImageSide].tips.map((tip, i) => <li key={i}> {tip} </li>)
                                            }
                                        </ul>
                                }
                                <button className="text-center w-44 h-12 bg-white text-black font-semibold rounded" onClick={gotIt} style={(companyBucket == "done" || companyBucket === "hascompanies") ? { ...buttonStyle, ...fontStyle, borderRadius: "30px", height: "auto", padding: "10px 40px" } : {}}>
                                    <span style={(companyBucket == "done" || companyBucket === "hascompanies" )? { ...fontStyle, color: "#ffffff", fontSize: "16px" } : { ...fontStyle }}>Got It</span>  </button>
                            </div>
                        </div>
                        <div className="z-10 absolute top-0 w-full h-full">
                            <img src={steps[activeImageSide].image} alt={"front teeth"} className="w-full h-full" />
                        </div>
                    </div>
            }
        </>

    )
}

export default DemoScreens