import React, { useEffect } from "react";
import TpProgress from "./tpProgress";
import TpInfo from "./tpInfo";
import BookAppointment from "./bookAppointment";
import DownloadPdf from "./downloadPdf";
import { postBookAppointment } from "../../Services/APIs/annotation";
import TpScanCarouselVietnam from "./carouselvietnam";


const BernFlow = () => {

    return (
        <div>
            <BookAppointment />
            <TpScanCarouselVietnam />
            <div className="border pt-3 rounded to-gray-300 my-5  mx-6 pb-6">
                <TpInfo />
            </div>
            <DownloadPdf />
        </div>
    );
};

export default BernFlow;
