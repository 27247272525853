import front from "../../../assets/periGum/front.png"
import left from "../../../assets/periGum/left.png"
import right from "../../../assets/periGum/right.png"

const steps = [
    {
        title: "Front teeth",
        image: front,
        tips: ["Posisikan gigi depan di dalam lingkaran", "Biarkan gigi sedikit terbuka"]
    },
    {
        title: "Left side",
        image: left,
        tips: ["Posisikan gigi depan di dalam lingkaran", "Biarkan gigi sedikit terbuka"]
    },
    {
        title: "Right side",
        image: right,
        tips: ["Posisikan gigi depan di dalam lingkaran", "Biarkan gigi sedikit terbuka"]
    },



]


export default steps
