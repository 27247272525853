import React, { useState, useEffect } from "react";
import Annotation from "../Annotation/annotation";
import { GetBucketFile } from "../../Services/APIs/annotation";
import { getJsonTagDetails } from "../../Services/APIs/annotation";
import { useLocation, useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';

const TpScanCarousel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [normalImages, setNormalImages] = useState([])
  const [txtTags, setTxtTags] = useState([])
  const [jsonTags, setJsonTags] = useState([])
  const subFolderName = JSON.parse(localStorage.getItem("response")).folder_name.split('/')[1]
  const companyS3Bucket = JSON.parse(localStorage.getItem("response")).bucket_name

  const teethImages = [JSON.parse(localStorage.getItem("response")).front_teeth];

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      navigate(location.pathname, { replace: true });
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate, location.pathname]);

  useEffect(() => {
    getJson(setJsonTags)
    getImages(setNormalImages)
  }, [])

  const getJson = async (storage) => {
    await Promise.all(teethImages.map(async (teeth, index) => {

      return await getJsonTagDetails(`input/${subFolderName}/${teethImages[index]}`).then((resData) => {
        if (resData.data.data !== "No Data") {
          const imageName = teeth;
          const jsonData = resData.data.data
          storage(prevState => [...prevState, { imageName, jsonData }])
          return { imageName, jsonData }
        } else {
          getTxtTags(setTxtTags)
        }
      }).catch((err) => {
        getTxtTags(setTxtTags)

      })
    }))
  }


  const getImages = async (storage) => {
    await Promise.all(teethImages.map(async (teeth, index) => {
      const fileName = `${subFolderName}/${teeth}`
      const fileFolder = `${companyS3Bucket}/input`
      return await GetBucketFile({ fileName, fileFolder, company: "pg" }).then((resData) => {
        if (resData.data.data !== "No Data") {
          const byteArray = new Uint8Array(resData.data.data.data)
          const blob = new Blob([byteArray], { type: 'image/jpeg' });
          const imageName = teeth
          const imageUrl = URL.createObjectURL(blob);
          storage(prevState => [...prevState, { imageName, imageUrl }])
          return { imageName, imageUrl }
        }
      }).catch((err) => {
        console.log(err)
      })
    }))

  }

  const getTxtTags = async (storage) => {
    await Promise.all(teethImages.map(async (teeth, index) => {
      const fileName = `${subFolderName}_${teeth.split('.')[0]}.txt`
      const fileFolder = `${companyS3Bucket}/output`
      return await GetBucketFile({ fileName, fileFolder, company: "pg" }).then((tags) => {
        const imgTags = tags.data.data.split("\n")
        const imageName = teeth
        storage(prevState => [...prevState, { imageName, imgTags }])
      }).catch((err) => {
        console.log(err)
      })
    }))
  }

  return (
    <div className="border rounded to-gray-300 mx-6">
      <div className="relative  h-100 ">
        {
          normalImages.length ?
            <div className="overflow-hidden rounded ">
              {teethImages.map((image, index) => (
                <div
                  key={index}
                  className={`absolute mt-5 transition-opacity duration-1000 `}
                >
                  <Annotation
                    jsonTags={jsonTags[jsonTags.findIndex(item => item.imageName == normalImages[currentIndex]?.imageName)]?.jsonData}
                    image={normalImages[currentIndex]}
                    texTags={txtTags[txtTags.findIndex(item => item.imageName == normalImages[currentIndex]?.imageName)]?.imgTags}
                  />
                </div>
              ))}
            </div>
            : <div className={`absolute m-5`}>Loading..</div>
        }


      </div>
    </div>
  );
};

export default TpScanCarousel;