export const getGingivitis = (t) => ({
    header: t("gingivitis.header"),
    description: t("gingivitis.description", { returnObjects: true }),
    specialCase: {
        header: t("gingivitis.specialCase.header"),
        tips: t("gingivitis.specialCase.tips", { returnObjects: true }),
    },
    action: {
        header: t("gingivitis.action.header"),
        tips: t("gingivitis.action.tips", { returnObjects: true }),
    },
});
