import React from 'react'

function PeriButton({ text, onClick }) {
  return (
    <button
      onClick={onClick}
      className="bg-[#C32D87] font-cera-pro-medium font-semibold text-white py-3 px-6 rounded-full transition duration-300 ease-in-out min-w-[271px] text-[20px]"
    >
      {text}
    </button>
  )
}

export default PeriButton