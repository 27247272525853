import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addImages } from "../../../Store/fullScan"
import steps from "./steps"
import crop from "../../../Utils/crop"
import dataURItoBlob from "../../../Utils/dataUriToBlob"
import { createScan, imageUpload, submitScan } from "../../../Services/APIs/scan"
import ReshootPopup from "./reshootError"
import "../../../App.css"
import { clearImages } from "../../../Store/fullScan";
import SuccessScreen from "./submitSuccess"
import { fontStyle, fontWeight, companyBucket } from "../../../Utils/tpscanStyle"
import { clearReports } from "../../../Store/reports";
import { clearHealth } from "../../../Store/teethHealth";
import { clearQuestionnaire } from "../../../Store/questionnaire";
import { useNavigate, useParams } from "react-router-dom";
import teethSides from "../../../Utils/teethSideNames"
import { postLogDetails } from "../../../Services/APIs/appDetails"
import { encrypt } from "../../../Utils/hmac"

const Preview = (props) => {
    const { image, setCamera, setToothPicker, activeImageSide, setDemo, quickScan, setImageSide } = props
    const dispatch = useDispatch()
    const { quickScanS3Folder } = useSelector((state) => state.quickScan)
    const { fullScanS3Folder } = useSelector((state) => state.fullScan)
    const [reshoot, setReshoot] = useState(false)
    const [errorType, setImageError] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [quickScanLoading, setQuickScanLoading] = useState(false)
    const [isQuickScanTaken, setQuickScanTaken] = useState(false)
    const [isSuccess, setSuccess] = useState(false)
    const [isError, setError] = useState(false);
    const { selectedOptions, reason } = useSelector(
        (state) => state.questionnaire
    );
    const navigate = useNavigate();
    const { images } = useSelector((state) => state.fullScan);
    const [isSubmitted, setSubmitted] = useState(false);
    const [croppedImage, setCroppedImage] = useState(null);

    const next = async () => {
        const rotate = activeImageSide === 4
        const croppedImage = await crop(image, 3 / 2, rotate).then(canvas => {
            return canvas.toDataURL("image/jpeg", 0.75);
        })
        await upload(croppedImage)
    }

    const handleReshoot = () => {
        setCamera(true)
        postLogDetails({
            uid: JSON.parse(localStorage.getItem("usertpi")).usertpi,
            app: JSON.parse(localStorage.getItem("companyBucket")).companyBucket,
            payLoad: `reshooting ${teethSides[activeImageSide]} teeth`
        })
    }

    const callCrop = async () => {
        const croppedImage = await crop(image, 3 / 2, false).then(canvas => {
            return canvas.toDataURL("image/jpeg", 0.75);
        })
        setCroppedImage(croppedImage)
    }

    useEffect(() => {
        callCrop()
    }, [])

    const upload = async (dataUri) => {
        const data = {
            uid: JSON.parse(localStorage.getItem('usertpi') || "{}").usertpi,
            folderName: JSON.parse(localStorage.getItem('usertpi') || "{}").usertpi,
            company: JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket,
            teethSide: `${teethSides[activeImageSide]} teeth`,
        }
        const encrypted = encrypt(data)
        const blob = dataURItoBlob(dataUri)
        const file = new File([blob], "report_image.jpeg", { type: "image/jpeg" });
        const formData = new FormData();
        formData.append("report_image", file);
        formData.append("_n", encrypted);
        setLoading(true)
        setReshoot(false)
        await imageUpload({ formData }).then((response) => {
            setLoading(false)

            const required = {
                title: steps[activeImageSide].title,
                dataUri: URL.createObjectURL(blob),
                file: response.data.data?.imageName,
                isReshoot: response.data.data?.is_reshoot,
                mouth_detection_message: response.data.data?.mouth_detection_message
            }
            if (required.isReshoot) {
                setReshoot(true)
                if (response.data.data?.mouth_detection_message === "mouth far away") {
                    setImageError("mouth far away")
                } else if (response.data.data?.mouth_detection_message === "blur detected") {
                    setImageError("blur detected")
                }
                else {
                    setImageError("general")
                }
            } else {
                if (quickScan) {
                    // dispatch(addQuickScan(required))
                    setQuickScanTaken(true)


                } else {
                    dispatch(addImages(required))
                    submit(required)

                    if (localStorage.getItem("isAutomated") && activeImageSide < 4) {
                        // setImageSide(activeImageSide + 1)
                        // setDemo(true)
                        console.log("came inside")
                        submit()
                    } else {
                        setToothPicker(true)
                    }

                }

            }

        }).catch((error) => {
            setLoading(false)
            setReshoot(true)
            setImageError("failed")
        })


    }

    const submit = async (data) => {
        setLoading(true);
        const info = {
            uid: JSON.parse(localStorage.getItem('usertpi') || "{}").usertpi,
            folderName: JSON.parse(localStorage.getItem('usertpi') || "{}").usertpi,
            company: JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket
        }
        await submitScan(info)
            .then(async (response) => {
                if (localStorage.getItem("usertpi")) {
                    localStorage.setItem(
                        "tpscore",
                        JSON.stringify(response.data?.data?.data)
                    );
                }
                const { images, ...mlResults } = response?.data?.data?.data;
                const { pdf_url, name, ...report_score } = mlResults;
                localStorage.setItem("pdf", `${name}.pdf`);
                createReport({ pdf_url, report_score, front_teeth: data?.file });
            })
            .catch((error) => {
                setLoading(false);
                setError(true);
                setReshoot(true)
                setImageError("failed")
            });
    };

    const createReport = async (info) => {

        const report = {
            uid: JSON.parse(localStorage.getItem('usertpi') || "{}").usertpi,
            company: JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket,
            pg_code: localStorage.getItem("pg-code"),
            is_full_scan: true,
            user_looking_for: selectedOptions,
            reason,
            ...info
        };

        await createScan(report)
            .then((response) => {
                localStorage.setItem("response", JSON.stringify(response.data.data.report));
                dispatch(clearImages());
                dispatch(clearReports());
                dispatch(clearHealth());
                dispatch(clearQuestionnaire());
                localStorage.setItem("scoreCard", true);
                localStorage.setItem("isScanCompleted", true)
                localStorage.removeItem("reloaded")
                localStorage.removeItem("pg-code")
                navigate("/pg/thanks")
            })
            .catch((error) => {
                setLoading(false);
                setError(true);
                setReshoot(true)
                setImageError("failed")
            });
    };

    return (
        <>
            {
                isSuccess ? <SuccessScreen isFullScan={false} /> :

                    <div className="h-screen">
                        <div className="relative top-0 h-full w-full">
                            <img src={image} alt="preview" className={`w-full object-cover h-full  ${activeImageSide === 4 && "ransform rotate-180 "}`} />
                        </div>


                        {

                            <div className="z-10 absolute bottom-5 w-full flex justify-evenly">
                                <button
                                    style={{ backgroundColor: "#1E1E78" }}
                                    className="  w-1/3 rounded-full  text-white py-2 px-4 shadow-2xl  font-bold "
                                    onClick={handleReshoot}>
                                    <span style={{ ...fontStyle, ...fontWeight }}>撮り直す
                                    </span> </button>
                                <button
                                    style={{ backgroundColor: "#1E1E78" }}
                                    className=" w-1/3 rounded-full  text-white py-2 px-4 shadow-2xl  font-bold "
                                    onClick={next} disabled={isLoading}

                                >
                                    {
                                        isLoading ? <div className="loader-container">
                                            <div className="loader dark-loader"></div>
                                        </div> : <span style={{ ...fontStyle, ...fontWeight }}>OK</span>
                                    }
                                </button>
                            </div>
                        }
                        {reshoot && <ReshootPopup setReshoot={setReshoot} errorType={errorType} setCamera={setCamera} setDemo={setDemo} />}
                    </div>
            }
        </>
    )
}


export default Preview