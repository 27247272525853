import React, { useState, useEffect } from "react";
import Annotation from "../Annotation/annotation";
import TpInfoLegends from "./tpInfoLegends";
import { GetBucketFile } from "../../Services/APIs/annotation";
import { getJsonTagDetails } from "../../Services/APIs/annotation";
import { useLocation, useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import BookPopup from "./bookPopup";
import { encrypt } from "../../Utils/hmac"

const TpScanCarouselVietnam = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [normalImages, setNormalImages] = useState([])
    const [txtTags, setTxtTags] = useState([])
    const [jsonTags, setJsonTags] = useState([])
    const subFolderName = JSON.parse(localStorage.getItem("response")).folder_name.split('/')[1]
    const companyS3Bucket = JSON.parse(localStorage.getItem("response")).bucket_name
    const [isPopupOpen, setPopup] = useState(false)
    const [isLoading, setLoading] = useState(true)

    const teethImages = [
        JSON.parse(localStorage.getItem("response")).front_teeth,
        JSON.parse(localStorage.getItem("response")).upper_jaw,
        JSON.parse(localStorage.getItem("response")).lower_jaw
    ];

    useEffect(() => {
        const handlePopState = (event) => {
            event.preventDefault();
            navigate(location.pathname, { replace: true });
        };
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate, location.pathname]);

    useEffect(() => {
        getJson(setJsonTags)
        getImages(setNormalImages)
    }, [])

    const getJson = async (storage) => {
        await Promise.all(teethImages.map(async (teeth, index) => {
            return await getJsonTagDetails(`input/${subFolderName}/${teethImages[index]}`).then((resData) => {
                if (resData.data.data !== "No Data") {
                    const imageName = teeth;
                    const jsonData = resData.data.data
                    storage(prevState => [...prevState, { imageName, jsonData }])
                    return { imageName, jsonData }
                } else {
                    getTxtTags(setTxtTags)
                }
            }).catch((err) => {
                getTxtTags(setTxtTags)

            })
        }))
    }

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };

    const getImages = async (storage) => {
        await Promise.all(teethImages.map(async (teeth, index) => {
            const data = {
                fileName: `${subFolderName}/${teeth}`,
                fileFolder: `${companyS3Bucket}/input`,
                uid: JSON.parse(localStorage.getItem('usertpi')).usertpi,
                folderName: JSON.parse(localStorage.getItem("usertpi")).usertpi,
                company: JSON.parse(localStorage.getItem("companyBucket")).companyBucket
            }

            return await GetBucketFile(data).then((resData) => {
                if (resData.data.data !== "No Data") {
                    const byteArray = new Uint8Array(resData.data.data.data)
                    const blob = new Blob([byteArray], { type: 'image/jpeg' });
                    const imageName = teeth
                    const imageUrl = URL.createObjectURL(blob);
                    storage(prevState => [...prevState, { imageName, imageUrl }])
                    return { imageName, imageUrl }
                }
            }).catch((err) => {
            })
        }))
        setLoading(false)
        setTimeout(() => { // once all the images loads, popup should open after 40 sec.
            setPopup(true)
        }, 40000)
    }

    const getTxtTags = async (storage) => {
        await Promise.all(teethImages.map(async (teeth, index) => {
            const data = {
                fileName: `${subFolderName}_${teeth.split('.')[0]}.txt`,
                fileFolder: `${companyS3Bucket}/output`,
                uid: JSON.parse(localStorage.getItem('usertpi')).usertpi,
                folderName: JSON.parse(localStorage.getItem("usertpi")).usertpi,
                company: JSON.parse(localStorage.getItem("companyBucket")).companyBucket
            }
            return await GetBucketFile(data).then((tags) => {
                const imgTags = tags.data.data.split("\n")
                const imageName = teeth
                storage(prevState => [...prevState, { imageName, imgTags }])
            }).catch((err) => {
            })
        }))
    }
    const handleSwipedLeft = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % teethImages.length);
    };

    const handleSwipedRight = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + teethImages.length) % teethImages.length);
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleSwipedLeft,
        onSwipedRight: handleSwipedRight,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });
    return (
        <div className="border rounded to-gray-300 mt-5  mx-6 ">
            <div {...swipeHandlers} className="relative">
                {
                    isLoading ?
                        <div className="loader-container h-72">
                            <div className="loader dark-loader"></div>
                        </div> :
                        <Annotation
                            companyS3Bucket={companyS3Bucket}
                            jsonTags={jsonTags[jsonTags.findIndex(item => item.imageName == normalImages[currentIndex]?.imageName)]?.jsonData}
                            image={normalImages[currentIndex]}
                            texTags={txtTags[txtTags.findIndex(item => item.imageName == normalImages[currentIndex]?.imageName)]?.imgTags}
                        />
                }
            </div>
            <div className="flex justify-center my-2">
                {teethImages.map((_, index) => (
                    <button
                        key={index}
                        className={`w-3 h-3 mx-1 rounded-full ${index === currentIndex ? "bg-gray-900" : "bg-gray-400"}`}
                        onClick={() => goToSlide(index)}
                    ></button>
                ))}
            </div>
            <TpInfoLegends />
            {/* {isPopupOpen && <BookPopup closePopup={setPopup} />} */}
        </div>
    );
};

export default TpScanCarouselVietnam;
