import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import close from "../../assets/Icons/close.png";
import checkedImg from "../../assets/Icons/photo-taken.png";
import dOneCheckedImg from "../../assets/Icons/d-one-photo-taken.jpg"
import error from "../../assets/svg/failed.svg";
import { useNavigate, useParams } from "react-router-dom";
import steps from "./steps";
import { clearImages } from "../../Store/fullScan";
import { createScan, createStarInsuranceScan, submitScan } from "../../Services/APIs/scan";
import SuccessScreen from "./submitSuccess";
import { clearReports } from "../../Store/reports";
import { clearHealth } from "../../Store/teethHealth";
import { clearQuestionnaire } from "../../Store/questionnaire";

import {
    buttonStyle,
    company_style_details,
    fontStyle,
    fontWeight,
    companyBucket,
} from "../../Utils/tpscanStyle";
import AlertPopup from "./alertPopup";

const ToothSelector = (props) => {

    const { dentalOfficeName } = useParams();
    const { setImageSide, setToothPicker } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { images, fullScanS3Folder } = useSelector((state) => state.fullScan);
    const { selectedOptions, reason } = useSelector(
        (state) => state.questionnaire
    );
    const [isLoading, setLoading] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);
    const [isError, setError] = useState(false);
    const [doneAlert, setAlert] = useState(false)

    const handleImageSide = (index) => {
        setImageSide(index);
        setToothPicker(false);
    };

    const submit = async () => {
        setLoading(true);
        const isStarInsuranceFlow = localStorage.getItem("scanType")
        const starInsuranceUser = isStarInsuranceFlow ? JSON.parse(localStorage.getItem("tl-star-user") || "{}") : {};
        const face_url = images.find((eachObj) => eachObj.title === "Face")?.file;

        const starInsuranceData = {
            face: face_url?.split("/").pop(),
            name: starInsuranceUser.name,
            phone_number: starInsuranceUser.phone_number,
        }
        const info = {
            uid: JSON.parse(localStorage.getItem('usertpi') || "{}").usertpi,
            folderName: JSON.parse(localStorage.getItem('usertpi') || "{}").usertpi,
            company: JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket,
            ...starInsuranceData
            
        }
        await submitScan(info).then(async (response) => {
            if (localStorage.getItem("usertpi")) {
                localStorage.setItem(
                    "tpscore",
                    JSON.stringify(response.data?.data?.data)
                );
            }
            const { images, ...mlResults } = response?.data?.data?.data;
            const { pdf_url, pdf_without_annotation, name, ...report_score } = mlResults;
            localStorage.setItem("pdf", `${name}.pdf`);
            createReport({ pdf_url, pdf_without_annotation, report_score, ...starInsuranceData });
        }).catch((error) => {
            setLoading(false);
            setError(true);
        });
    };

    const createReport = async (data) => {
        const tooth = {
            front_teeth: "Front teeth",
            upper_jaw: "Upper jaw",
            lower_jaw: "Lower jaw",
            left_side: "Left side",
            right_side: "Right side",
            face: "Face"
        };
        const report = {
            uid: JSON.parse(localStorage.getItem('usertpi') || "{}").usertpi,
            company: JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket,
            is_full_scan: true,
            user_looking_for: selectedOptions,
            reason,
            ...data
        };
        for (const key in tooth) {
            images.forEach((eachObj) => {
                if (tooth[key] === eachObj.title) {
                    report[key] = eachObj.file;
                }
            });
        }

        localStorage.getItem("scanType") ? starInsuranceReport(report) : tpReport(report);

    };

    const starInsuranceReport = async (report) => {
        const { is_full_scan, reason, user_looking_for, ...rest } = report;
        
        await createStarInsuranceScan(rest).then((response) => {
            dispatch(clearImages());
            localStorage.setItem("isScanCompleted", true)
            localStorage.removeItem("reloaded")
            localStorage.removeItem("tl-star-user")
            navigate("/tl-star/thanks");
        }).catch((error) => {
            setLoading(false);
            setError(true);
        });

    }

    const tpReport = async (report) => {
        await createScan(report).then((response) => {
            localStorage.setItem("response", JSON.stringify(response?.data?.data?.report));
            dispatch(clearImages());
            localStorage.setItem("scoreCard", true);
            localStorage.setItem("isScanCompleted", true)
            localStorage.removeItem("reloaded")
            localStorage.getItem("isAutomated") ? navigate("/selfie/thanks") : setSubmitted(true)
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            setError(true);
        });
    }



    const ErrorUI = () => {
        return (
            <div className="h-full absolute top-0 z-10 w-full flex justify-center items-center bg-black/[0.3] backdrop-blur-sm">
                <div className="bg-white w-3/4 flex flex-col items-center p-5 rounded-md shadow-sm">
                    <div className="text-end w-full">
                        {
                            companyBucket === "done" ?
                                <div className="flex justify-end">
                                    <a
                                        href="https://app.dentistry.one/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ textDecoration: "none", color: "inherit" }}>
                                        <img src={close} alt="close_img" width="26px" />
                                    </a>
                                </div> :
                                <button
                                    type="button"
                                    onClick={() => { setError(false) }}>
                                    <img src={close} className="w-6 h-6" alt="close" />
                                </button>
                        }
                    </div>
                    <img src={error} alt="error" className=" w-40 h-40" />
                    <p className="text-center my-5 font-medium">
                        Sorry!! AI is experiencing some difficulty. Please retry in a moment.
                    </p>
                    {companyBucket !== "done" && <div className=" w-full ">
                        <button
                            type="button"
                            className="bg-black text-white w-full h-11 font-semibold text-base shadow-2xl"
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            Back to home page
                        </button>
                    </div>}
                </div>
            </div>
        );
    };

    const Button = (props) => {
        const { i, name, title } = props;
        const checked = images.find((eachObj) => eachObj.title === title);

        return (
            <>
                {checked ? (
                    <img src={(companyBucket === "done" || companyBucket === "hascompanies") ? dOneCheckedImg : checkedImg} alt="checked" className="w-20 h-20 mx-2 rounded-full" />
                ) : (
                    <button
                        className="w-20 h-20 text-xl text-center bg-white rounded-full shadow mx-2"
                        onClick={() => {
                            handleImageSide(i);
                        }}
                        style={(companyBucket === "done" || companyBucket === "hascompanies") ? { fontSize: "16px" } : {}}
                    >
                        {name}
                    </button>
                )}
            </>
        );
    };
    const doNavigate = () => {
        (companyBucket === "done" || companyBucket === "hascompanies") ? setAlert(true) : window.history.back()
    };

    return (
        <>
            {isSubmitted ? (
                <SuccessScreen isFullScan={true} />
            ) : (
                <div className={(companyBucket == "done" || companyBucket === "hascompanies") ? "bg-custom-gradient h-full flex flex-col" : "h-full flex flex-col bg-zinc-100"}>
                    <div className="flex justify-between items-center py-4 px-3">
                        <p
                            className="text-sm"
                            style={
                                (companyBucket == "done" || companyBucket === "hascompanies")
                                    ? { ...fontStyle, fontSize: "14px" }
                                    : { ...fontWeight, ...fontStyle }
                            }
                        >
                            Tap to capture and preview your photo
                        </p>
                        <button className="" onClick={() => doNavigate()}>
                            <img src={close} className="w-7 h-7" alt="close" />
                        </button>
                    </div>
                    <div
                        className="flex-grow flex flex-col items-center justify-center font-medium"
                        style={
                            (companyBucket == "done" || companyBucket === "hascompanies")
                                ? { ...fontStyle }
                                : { ...fontWeight, ...fontStyle }
                        }
                    >
                        <Button i={4} name={"Upper"} title={steps[4].title} />
                        <div
                            className="flex justify-center my-4"
                            style={
                                (companyBucket == "done" || companyBucket === "hascompanies")
                                    ? { ...fontStyle }
                                    : { ...fontWeight, ...fontStyle }
                            }
                        >
                            <Button i={1} name={"Left"} title={steps[1].title} />
                            <Button i={0} name={"Front"} title={steps[0].title} />
                            <Button i={2} name={"Right"} title={steps[2].title} />
                        </div>
                        <Button i={3} name={"Lower"} title={steps[3].title} />
                    </div>
                    <div className="flex justify-center items-center mb-3">

                        {images.length >= 5 && (
                            <button
                                style={
                                    (companyBucket == "done" || companyBucket === "hascompanies")
                                        ? {
                                            ...buttonStyle,
                                            ...fontStyle,
                                            ...fontWeight,
                                            borderRadius: "30px",
                                        }
                                        : { ...buttonStyle, ...fontStyle, ...fontWeight }
                                }
                                type="button"
                                className="w-40 h-11 bg-black text-white font-semibold m-2 rounded"
                                onClick={submit}
                                disabled={isLoading}
                            >
                                {isLoading ? "Processing..." : "Submit Scan"}
                            </button>
                        )}
                    </div>
                    {isError && <ErrorUI />}
                    {doneAlert && <AlertPopup setPopup={setAlert} />}
                </div>
            )}
        </>
    );
};

export default ToothSelector;
