import { useState } from "react";
import successImage from "../../../assets/onboarding-images/success.png";
import { createScan, submitScan } from "../../../Services/APIs/scan";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearImages } from "../../../Store/fullScan"
import ErrorUI from "./errorUI";


const SubmitReport = () => {
    const navigate = useNavigate()
    const { images, fullScanS3Folder } = useSelector((state) => state.fullScan);
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);

    const submit = async () => {
        setLoading(true)
        const info = {
            uid: JSON.parse(localStorage.getItem('usertpi') || "{}").usertpi,
            folderName: JSON.parse(localStorage.getItem('usertpi') || "{}").usertpi,
            company: JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket
        }
        await submitScan(info).then(async (response) => {
            if (localStorage.getItem("usertpi")) {
                localStorage.setItem(
                    "tpscore",
                    JSON.stringify(response.data?.data?.data)
                );
            }
            const { images, ...mlResults } = response?.data?.data?.data;
            const { pdf_url, name, ...report_score } = mlResults;
            localStorage.setItem("pdf", `${name}.pdf`);
            createReport({ pdf_url, report_score });
        }).catch((error) => {
            setLoading(false);
            setError(true);
        });
    }

    const createReport = async (data) => {
        try {
            const tooth = {
                front_teeth: "Front teeth",
                upper_jaw: "Upper jaw",
                lower_jaw: "Lower jaw",
                left_side: "Left side",
                right_side: "Right side",
                face: "Face"
            };
            const report = {
                uid: JSON.parse(localStorage.getItem('usertpi') || "{}").usertpi,
                company: JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket,
                is_full_scan: true,
                ...data
            };
            for (const key in tooth) {
                images.forEach((eachObj) => {
                    if (tooth[key] === eachObj.title) {
                        report[key] = eachObj.file;
                    }
                });
            }
            await createScan(report).then((response) => {
                localStorage.setItem("response", JSON.stringify(response?.data?.data?.report));
                dispatch(clearImages());
                localStorage.setItem("scoreCard", true);
                localStorage.setItem("isScanCompleted", true)
                localStorage.removeItem("reloaded")
                navigate("/vietnam/report-summary", { replace: true })
            }).catch((error) => {
                setLoading(false);
                setError(true);
            });
        } catch (error) {
            setLoading(false);
            setError(true);

        }


    };
    return (
        <div className="h-full w-full">
            <div className="h-3/4 relative bg-orange-100">
                <img
                    src={successImage}
                    alt="success"
                    className="w-full h-full object-cover"
                />
                <div className="absolute bottom-0 h-2/5 w-full bg-gradient-to-t from-white"></div>
            </div>
            <div className="w-full absolute bottom-4 px-5">
                <h1 className=" text-26 font-medium text-left leading-8">
                Chụp ảnh thành công!
                </h1>
                <p className="text-base text-left mt-2">
                Gửi ảnh để nhận được phân tích sức khỏe răng miệng cơ bản
                </p>
                <button
                    type="button"
                    className="bg-uv-dark-blue rounded-md text-white w-full h-11 mb-1 mt-5 font-semibold text-base shadow-2xl"
                    onClick={submit}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <div className="loader-container">
                            <div className="loader dark-loader"></div>
                        </div>
                    ) : (
                        <span> gửi ảnh </span>
                    )}
                </button>

            </div>
            {isError && <ErrorUI setError={setError} />}

        </div>
    )
}


export default SubmitReport