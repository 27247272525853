import { useEffect } from "react";
import pgText1 from "../../../assets/pg/pg_text_1.png";
import pgImg1 from "../../../assets/pg/pg_img_1.png";
import pgImg2 from "../../../assets/pg/pg_img_2.png";
import pgLink3 from "../../../assets/pg/pg_link_3.png";
import pgLink1 from "../../../assets/pg/pg_link_1.png";
import pgImg4_1 from "../../../assets/pg/pg_img_4_1.png";
import pgImg4_2 from "../../../assets/pg/pg_img_4_2.png";
import pgReport from "../../../assets/pg/bg_pg.png";
import pgRetailer from "../../../assets/pg/shoplist_pg.png";
import TpScanCarousel from "../../Bearn/tpScanCarousel";
import { postLogDetails } from '../../../Services/APIs/appDetails';
import dataURItoBlob from "../../../Utils/dataUriToBlob";
import pgRetailerLink from "../../../assets/pg/pg_retailer_link.jpg"
import backgroundtemplate from "../../../assets/pg/backgroundtemplate.png"
import million from "../../../assets/pg/1-million-yen-split.png";
import brush from "../../../assets/pg/brush.jpg"

const ThankYou = () => {

  const images = [
    { src: pgReport, alt: "report_img" },
    { src: pgText1, alt: "product_link" },
    { src: pgImg1, alt: "brush_img" },
    { src: pgImg2, alt: "store_img" },
  ];


  const uid = JSON.parse(localStorage.getItem("usertpi") || '{}').usertpi
  const app = JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket
  const handleLinkClick = (data) => {
    postLogDetails({ uid, app, payLoad: data });
  };


  postLogDetails({ uid, app, payLoad: "Landed Thanks Page" });

  useEffect(() => {
    localStorage.setItem("hasScanned", true)
  }, [])

  return (
    <div className="min-h-screen bg-gray-50">
      <TpScanCarousel />

      {images.map((image, index) => (
        <img key={index} src={image.src} alt={image.alt} className="w-full h-auto object-cover" />
      ))}

      <div
        onClick={() => handleLinkClick("Clicked product-collections")}
      >
        <a href="https://www.oralb.braun.co.jp/ja-jp/product-collections/electric-toothbrushes/io" target="_blank" rel="noopener noreferrer">
          <img src={pgLink1} alt="product_link" className="w-full h-auto object-cover" />
        </a>
      </div>


      <div className="relative w-full h-auto">
        <img
          src={backgroundtemplate}
          alt="offer_img"
          className="w-full h-auto object-cover"
        />
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex h-3/5 w-full justify-center items-end">
          <a
            href="https://www.oralb.braun.co.jp/ja-jp/campaign/1-million-yen-split"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block h-full"
            onClick={() => handleLinkClick("1-million-yen-split")}

          >

            <img
              src={million}
              alt="bottom_img_1"
              className="w-auto h-full object-contain"
            />
          </a>

          <a
            href="https://www.oralb.braun.co.jp/ja-jp/campaign/1-out-of-3-free"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block h-full"
            onClick={() => handleLinkClick("1-out-of-3-free")}

          >
            <img
              src={brush}
              alt="bottom_img_2"
              className="w-auto h-full object-contain"
            />
          </a>
        </div>

      </div>

      <div className="w-full -mt-1"
        onClick={() => handleLinkClick("Clicked campaign/great-sale")}
      >
        <a
          href="https://www.oralb.braun.co.jp/ja-jp/campaign/great-sale"
          className="w-full bg-transparent cursor-pointer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={pgRetailerLink} alt="campaign_link" className="w-full h-auto object-cover " />
        </a>
      </div>

      <div
        onClick={() => handleLinkClick("Clicked oralb-braun-onlinestore")}
      >
        <a href="https://www.oralb-braun-onlinestore.jp/f/deal" target="_blank" rel="noopener noreferrer">
          <img src={pgLink3} alt="offer_link" className="w-full h-auto object-cover" />
        </a>
      </div>
    </div>
  );
};

export default ThankYou;
