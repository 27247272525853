import { useState } from "react";
import close from "../../../assets/Icons/close-white.png"
import steps from "./steps";
import UpperJawDemonstartion from "./upperJawDemonstration";
import ipadFront from "../../../assets/unilever/front-demo-ipad.png"
import { postLogDetails } from "../../../Services/APIs/appDetails";
import teethSides from "../../../Utils/teethSideNames";

const DemoScreens = (props) => {
    const [isUpperJawDemonstration, setUpperJawDemonstration] = useState(false)
    const { activeImageSide, setToothPicker, setDemo, setCamera, quickScan } = props
    postLogDetails({
        uid: JSON.parse(localStorage.getItem("usertpi") || "{}").usertpi,
        app: `${JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket}${localStorage.getItem("isUnileverIpad") ? "-ipad" : ""}`,
        payLoad: `Viewed ${teethSides[activeImageSide]} teeth demo screen`
    })

    const gotIt = () => {
        setDemo(false)
        setCamera(true)
    }

    return (
        <>

            {
                isUpperJawDemonstration ? <UpperJawDemonstartion gotIt={gotIt} back={setUpperJawDemonstration} /> :
                    <div className="h-full w-full">
                        <div className="z-20 relative h-full w-full text-end text-white ">
                            <button className="p-1" onClick={() => { window.history.back() }}> <img src={close} className="w-7 h-7 m-2" alt="close" /> </button>
                            <h1 className=" text-center text-2xl font-semibold mt-5 md:text-3xl" >{steps[activeImageSide].title}</h1>
                            <div className="w-full flex flex-col items-center absolute bottom-8 text-center">
                                {
                                    activeImageSide === 4 ? <button
                                        className="text-center h-12 w-44 bg-white text-black font-semibold rounded mb-3"

                                        onClick={() => { setUpperJawDemonstration(true) }}
                                    >
                                        <span >Lihat tutorial</span>  </button> :
                                        <ul className=" list-outside text-white text-start me-5 ms-14 mb-10 list-disc md:text-lg" >
                                            {
                                                steps[activeImageSide].tips.map((tip, i) => <li key={i}> {tip} </li>)
                                            }
                                        </ul>
                                }
                                <button className="text-center w-44 h-12 bg-red-unilever text-white font-semibold rounded md:text-2xl md:h-16" onClick={gotIt} >
                                    <span>Mengerti</span>  </button>
                            </div>
                        </div>
                        <div className="z-10 absolute top-0 w-full h-full flex justify-center bg-white">
                            <img src={steps[activeImageSide].image} alt={"front teeth"} className={`w-full h-full md:w-auto md:h-full ${activeImageSide === 0 ? "md:hidden" : ""} `} />
                            <img src={ipadFront} alt={"front teeth"} className={`w-full h-full md:w-auto md:h-full hidden ${activeImageSide === 0 ? "md:block " : " "} `} />
                        </div>
                    </div>
            }
        </>

    )
}

export default DemoScreens