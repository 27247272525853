import { useEffect, useState } from "react";
import DemoScreens from "./demoScreens";
import Shoot from "./shoot";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SubmitPhotos from "./submitScreen";

const ScanPage = () => {
    const navigate = useNavigate()
    const { images } = useSelector((state) => state.fullScan);
    const [activeImageSide, setImageSide] = useState(images.length < 3 ? images.length : 0);
    const [isToothPicker, setToothPicker] = useState(false)

    useEffect(() => {

        if (!localStorage.getItem("usertpi") || localStorage.getItem("isScanCompleted")) {
            navigate("/peri-gum")
        }
        if (images.length >= 3) {
            setToothPicker(true)
        }
    }, [activeImageSide])

    return (
        <div className="h-full w-full">
            {
                isToothPicker ?
                    <SubmitPhotos />
                    : <Shoot activeImageSide={activeImageSide} setImageSide={setImageSide} setToothPicker={setToothPicker} />
            }
        </div>
    )
}

export default ScanPage;