import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import back from "../../../assets/Icons/Back-white.png";
import close from "../../../assets/Icons/close-white.png";
import { getAdvancedGumDisease } from "./getAdvancedGumDisease";
import { getRiskOfGingivitis } from "./atRiskOfGingivitis";
import { getGingivitis } from "./gingivitis";
import { getHealthyGums } from "./healthyGums";
import "./index.css";

const GumHealthReport = () => {
    const navigate = useNavigate();
    const { t } = useTranslation("report");

    const analysedReport = localStorage.getItem("peri-report");

    const analysisCard = {
        "at risk of gingivitis": getRiskOfGingivitis(t),
        "gingivitis": getGingivitis(t),
        "advanced gum disease": getAdvancedGumDisease(t),
        "healthy gums": getHealthyGums(t),
    };

    const health = analysisCard[analysedReport];

    const goBack = () => {
        window.history.back();
    };

    return (
        <div className="peri-gum-health-container h-full w-full relative">
            <div className="absolute top-0 left-0 h-full w-full bg-pink-500 bg-opacity-85 flex flex-col justify-between p-3">
                <div className="flex items-center">
                    <button type="button" onClick={goBack}>
                        <img src={back} alt="back" className="h-9" />
                    </button>
                    <p className="text-white text-lg font-cera-pro-medium font-semibold flex-grow text-center">
                        {t("report-title")}
                    </p>
                    <button type="button" onClick={goBack}>
                        <img src={close} alt="close" className="h-9" />
                    </button>
                </div>

                <div className="bg-white relative font-cera-pro-medium font-semibold flex flex-col flex-grow my-5 mx-3 rounded-xl py-3 px-4 overflow-y-auto">
                    <h2 className="text-peri-pink  text-[28px]">
                        {health?.header}
                    </h2>

                    <div className="text-peri-blue font-cera-pro-medium text-sm my-3 ">
                        {health?.description?.map((text, i) => (
                            <p key={i} className="my-3 font-normal">
                                {text}
                            </p>
                        ))}
                    </div>

                    {health?.specialCase && (
                        <>
                            <h2 className="text-peri-pink font-cera-pro-medium font-semibold text-lg">
                                {health.specialCase.header}
                            </h2>
                            <ul className="list-disc px-5">
                                {health.specialCase.tips.map((tip, index) => (
                                    <li key={index} className="text-peri-blue text-sm my-3 font-cera-pro-medium font-normal">
                                        {tip}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}

                    <h2 className="text-peri-pink font-cera-pro-medium font-semibold text-lg">
                        {health?.action?.header}
                    </h2>
                    <ul className="list-disc px-5">
                        {health?.action?.tips?.map((tip, index) => (
                            <li key={index} className="text-peri-blue font-cera-pro-medium font-normal text-sm my-3">
                                {tip}
                            </li>
                        ))}
                    </ul>

                    <button
                        type="button"
                        className="text-peri-blue border border-gray-500 text-2xl font-semibold px-10 py-2 rounded-full mx-auto font-cera-pro-medium "
                        onClick={() => navigate("/peri-gum/thank-you")}
                    >
                        {t("submit-button")}
                    </button>
                </div>

                <p className="text-xs text-white py-2 px-3 font-cera-pro-medium">{t("report-footer")}</p>
            </div>
        </div>
    );
};

export default GumHealthReport;
