import { useEffect, useState } from "react";
import welcomeTeeth from "../../../assets/illustrations/welcomeTeeth.png";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { postLogDetails } from "../../../Services/APIs/appDetails";
import { generateRandomAlphaNumeric } from "../../../Utils/alphaNumeric";
import { getTpStatus } from "../../../Services/APIs/scan";


const UVLandingPage = () => {
    const navigate = useNavigate()
    const { uid } = useParams()
    const [isLoading, setLoading] = useState(false)
    const [isError, setError] = useState('')
    const { images } = useSelector((state) => state.fullScan);


    useEffect(() => {
        localStorage.setItem("companyBucket", JSON.stringify({ companyBucket: "unileverviet" }));
        localStorage.setItem("isAutomated", true)
        localStorage.removeItem("scanType");
        !localStorage.getItem("reloaded") && reload()
        validateUid(uid)

    }, [])

    const validateUid = async (uid) => {
        if (uid && uid.length <= 2) {
            setError("Độ dài UID phải lớn hơn 2")
        }
        else if (uid && uid !== "invalid-url") {
            setError('')
            setLoading(true)
            await getTpStatus({ uid, c: "unileverviet" }).then((res) => {
                setError('')
            }).catch((err) => {
                err.status === 410 ? setError(`Báo cáo với ${uid} đã được sử dụng!`) : setError("Đã xảy ra lỗi")
            }).finally(() => {
                setLoading(false)
            })
        }
        else {
            setError("Liên kết không hợp lệ. Vui lòng kiểm tra URL")
        }

    }

    const reload = () => {
        localStorage.setItem("reloaded", true)
        window.location.reload()
    }

    const generateInfo = () => {
        const randomString = generateRandomAlphaNumeric(2);
        const timestamp = Date.now().toString();
        const randomString2 = generateRandomAlphaNumeric(1)
        const unique = randomString + timestamp + randomString2
        localStorage.setItem("usertpi", JSON.stringify({ usertpi: unique }));
        postLogDetails({ uid: unique, app: "unileverviet", payLoad: `visited landing page` })
    }

    const takeScan = () => {
        localStorage.removeItem("isScanCompleted")
        localStorage.setItem("usertpi", JSON.stringify({ usertpi: uid }));
        navigate("/vietnam/scan")
    }
    return (
        <div className="h-full">
            <div className="h-1/2">
                <img
                    src={welcomeTeeth}
                    alt="welcome teeth"
                    className="saturation h-full w-full object-contain"
                />
            </div>

            <div className="h-1/2 relative flex flex-col justify-center">
                <h5 className=" text-2xl font-semibold text-center mb-3 mx-3">
                    Vui lòng chụp ảnh răng miệng theo hướng dẫn để P/S có thể kiểm tra sức khỏe răng miệng của bạn nhé!
                </h5>
                <div>
                    <div className="h-48  flex flex-col items-center px-4 py-2">
                        {

                            isError ? <p className="text-red-500 text-xl text-center">{isError}</p> :
                                <button
                                    type="button"
                                    className=" bg-uv-dark-blue rounded text-white w-full h-11 font-semibold text-base mb-4"
                                    onClick={takeScan}
                                    disabled={isLoading}
                                >
                                    {
                                        isLoading ?
                                            <div className="loader-container">
                                                <div className="loader dark-loader"></div>
                                            </div> : <span>  Bắt đầu </span>
                                    }
                                </button>
                        }
                    </div>
                </div>

                <div className="absolute bottom-11 w-full px-4">
                    <p className=" text-xs font-medium flex justify-evenly text-center text-black opacity-60 " >
                        Hình ảnh răng miệng của bạn sẽ được bảo mật tuyệt đối & chỉ dùng cho mục đích phân tích, tư vấn sức khỏe răng miệng tại đây.
                    </p>
                </div>
            </div>

        </div>
    );
}


export default UVLandingPage;