export const getRiskOfGingivitis = (t) => ({
    header: t("riskOfGingivitis.header"),
    description: t("riskOfGingivitis.description", { returnObjects: true }),
    specialCase: {
        header: t("riskOfGingivitis.specialCase.header"),
        tips: t("riskOfGingivitis.specialCase.tips", { returnObjects: true }),
    },
    action: {
        header: t("riskOfGingivitis.action.header"),
        tips: t("riskOfGingivitis.action.tips", { returnObjects: true }),
    },
});
