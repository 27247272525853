const UniversalLoader = () => {
    return (
        <div className="h-screen w-full flex justify-center items-center">
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        </div>
    )
}


export default UniversalLoader;