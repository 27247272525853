import { Navigate, Route, Routes } from "react-router-dom"
import UVLandingPage from "./Landing"
import FullScan from "./Scan"
import BernFlow from "../Bearn/bernFlow"

const Vietnamese = () => {
    return (
        <Routes>
            <Route path="/" element={<UVLandingPage />} />
            <Route path="/:uid" element={<UVLandingPage />} />
            <Route path="/scan" element={<FullScan />} />
            <Route path="/report-summary" element={<BernFlow />} />
            {/* <Route path="*" element={<Navigate to="/vietnam/invalid-url" />} /> */}
        </Routes>
    )
}

export default Vietnamese