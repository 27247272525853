import { useEffect } from "react"
import { postLogDetails } from "../../../Services/APIs/appDetails"
import { useTranslation } from "react-i18next"

const FrontCameraError = ({ back }) => {
    const { t } = useTranslation("selfiefailed")

    useEffect(() => {
        postLogDetails({
            uid: JSON.parse(localStorage.getItem("usertpi")).usertpi,
            app: JSON.parse(localStorage.getItem("companyBucket")).companyBucket,
            payLoad: "selfie camera not found"
        })

    }, [])

    return (
        <div className="h-full z-20 absolute w-full top-0 bg-black/[0.3] backdrop-blur-sm flex items-center justify-center">
            <div className="bg-white w-11/12 rounded-md p-4">
                <h1 className=" text-xl font-bold">{t("header")}</h1>

                <p className="ms-2 mt-3"><span className="me-1"> &#8226;</span> {t("tip1")}</p>
                <p className="ms-2"><span className="me-1"> &#8226;</span>  {t("tip2")} </p>

                <p className="mt-3"> {t("retryRequest")} </p>
                <div className="flex mt-4">
                    <button
                        type="button"
                        className="w-full bg-black text-white py-2 me-3 rounded"
                        onClick={() => { back(false) }}>  {t("continue")} </button>
                    <button
                        type="button"
                        className="w-full bg-black text-white py-2 rounded"
                        onClick={() => window.location.reload()}>
                        {t("retry")}
                    </button>
                </div>

            </div>
        </div>
    )
}

export default FrontCameraError