import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import close from "../../../assets/Icons/close-white.png"
import front from "../../../assets/periGum/front.png"
import left from "../../../assets/periGum/left.png"
import right from "../../../assets/periGum/right.png"
import { fontWeight, fontStyle, companyBucket, company_style_details, buttonStyle } from "../../../Utils/tpscanStyle"
import { postLogDetails } from "../../../Services/APIs/appDetails";
import teethSides from "../../../Utils/teethSideNames";
import { useTranslation } from "react-i18next";

const DemoScreens = (props) => {
    const { t, i18n } = useTranslation("demoscreen")
    const { activeImageSide, setToothPicker, setDemo, setCamera } = props

    const stepsContent = t("content", { returnObjects: true })
    const steps = stepsContent.map((step, i) => {
        const images = [front, left, right]
        return { ...step, image: images[i] }

    })


    postLogDetails({
        uid: JSON.parse(localStorage.getItem("usertpi") || "{}").usertpi,
        app: JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket + `-${i18n.language}`,
        payLoad: `viewed ${teethSides[activeImageSide]} teeth demo screen`
    })

    const gotIt = () => {
        setDemo(false)
        setCamera(true)
    }

    return (
        <div className="h-full w-full">
            <div className="z-20 absolute bottom-0 h-full w-full text-end text-white ">
                <button className="p-1" onClick={() => { localStorage.getItem("isAutomated") ? window.history.back() : setToothPicker(true) }}> <img src={close} className="w-7 h-7 m-2" alt="close" /> </button>
                <h1 className=" text-center text-[34px] font-semibold mt-5 font-cera-pro-medium" >
                    {steps[activeImageSide].title.split("\n").map((line, index) => (
                        <span key={index}>
                            {line}
                            <br />
                        </span>
                    ))}
                </h1>
                <div className="w-full flex flex-col items-center absolute bottom-8 text-center">

                    <ul className=" list-outside text-white text-start me-5 ms-14 mb-10 list-disc" >
                        {
                            steps[activeImageSide].tips.map((tip, i) => <li key={i} className=" text-base font-cera-pro-medium"> {tip} </li>)
                        }
                    </ul>

                    <button className="text-center text-2xl w-5/6 h-12 bg-white text-peri-blue font-semibold rounded-full font-cera-pro-medium" onClick={gotIt} >
                        {t("callToAction")}
                    </button>
                </div>
            </div>
            <div className="z-10 absolute top-0 w-full h-full">
                <img src={steps[activeImageSide].image} alt={"front teeth"} className="w-full h-full" />
            </div>
        </div>


    )
}

export default DemoScreens