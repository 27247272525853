
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addImages } from "../../../Store/fullScan"
import steps from "./steps"
import crop from "../../../Utils/crop"
import dataURItoBlob from "../../../Utils/dataUriToBlob"
import { createScan, imageUpload, submitScan } from "../../../Services/APIs/scan"
import ReshootPopup from "./reshootError"
import "../../../App.css"
import { addQuickScan, clearQuickScan } from "../../../Store/quickScan"
import { fontStyle, fontWeight, companyBucket } from "../../../Utils/tpscanStyle"
import { useNavigate } from "react-router-dom"
import { postLogDetails } from "../../../Services/APIs/appDetails"
import teethSides from "../../../Utils/teethSideNames"
import { encrypt } from "../../../Utils/hmac"

const Preview = (props) => {
    const navigate = useNavigate()
    const { image, setCamera, setToothPicker, activeImageSide, setDemo, quickScan, setImageSide } = props
    const dispatch = useDispatch()
    const { quickScanS3Folder, imageFile } = useSelector((state) => state.quickScan)
    const { fullScanS3Folder } = useSelector((state) => state.fullScan)
    const [reshoot, setReshoot] = useState(false)
    const [errorType, setImageError] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [quickScanLoading, setQuickScanLoading] = useState(false)
    const [isQuickScanTaken, setQuickScanTaken] = useState(false)


    const next = async () => {
        const rotate = activeImageSide === 4
        const croppedImage = await crop(image, 3 / 2, rotate).then(canvas => {
            return canvas.toDataURL("image/jpeg", 0.75);
        })
        await upload(croppedImage)
    }

    const handleReshoot = () => {
        setCamera(true)
        postLogDetails({
            uid: JSON.parse(localStorage.getItem("usertpi")).usertpi,
            app: `${JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket}${localStorage.getItem("isUnileverIpad") ? "-ipad" : ""}`,
            payLoad: `reshooting ${teethSides[activeImageSide]} teeth`
        })
    }

    const upload = async (dataUri) => {
        const data = {
            uid: JSON.parse(localStorage.getItem("usertpi")).usertpi,
            folderName: JSON.parse(localStorage.getItem("usertpi")).usertpi,
            company: JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket,
            teethSide: `${teethSides[activeImageSide]} teeth`,
            isIpad: localStorage.getItem("isUnileverIpad"),
        }
        const encrypted = encrypt(data)
        const blob = dataURItoBlob(dataUri)
        const file = new File([blob], "report_image.jpeg", { type: "image/jpeg" });
        const formData = new FormData();
        formData.append("report_image", file);
        formData.append("_n", encrypted);
        setLoading(true)
        setReshoot(false)
        await imageUpload({ formData }).then((response) => {
            setLoading(false)

            const required = {
                title: steps[activeImageSide].title,
                dataUri: URL.createObjectURL(blob),
                file: response.data.data?.imageName,
                isReshoot: response.data.data?.is_reshoot,
                mouth_detection_message: response.data.data?.mouth_detection_message
            }
            if (required.isReshoot) {
                setReshoot(true)
                if (response.data.data?.mouth_detection_message === "mouth far away") {
                    setImageError("mouth far away")
                } else if (response.data.data?.mouth_detection_message === "blur detected") {
                    setImageError("blur detected")
                }
                else {
                    setImageError("general")
                }
            } else {

                dispatch(addImages(required))

                if (activeImageSide === 0) {
                    setImageSide(3)
                    setDemo(true)

                } else if (activeImageSide === 3) {
                    setImageSide(4)
                    setDemo(true)

                } else if (activeImageSide === 4) {
                    setToothPicker(true)

                } else {
                    setImageSide(0)
                    setDemo(true)
                }


            }



        }).catch((error) => {
            setLoading(false)
            setReshoot(true)
            setImageError("failed")
        })


    }

    const quickScanSubmit = async (data) => {
        setQuickScanLoading(true)
        await submitScan({ folderName: quickScanS3Folder }).then((response) => {
            const { images, ...mlResults } = response?.data?.data?.data
            const { pdf_url, name, ...report_score } = mlResults

            const report = {
                pdf_url,
                report_score,
                front_teeth: data.file,
                is_full_scan: false,
                is_single_scan: true
            }
            localStorage.setItem("pdf_url", pdf_url)
            createReport(report)
        }).catch((error) => {
            setReshoot(true)
            setImageError("failed")
            setQuickScanLoading(false)
        })


    }

    const createReport = async (report) => {
        await createScan(report).then((response) => {
            dispatch(clearQuickScan())
            localStorage.setItem("isScanCompleted", true)
            localStorage.removeItem("reloaded")
            localStorage.removeItem("template")
            localStorage.removeItem("usertpi")
            navigate("/unilever/i-thanks")
        }).catch((error) => {
            setReshoot(true)
            setImageError("failed")
            setQuickScanLoading(false)
        })
    }


    return (


        <div className="h-full">
            <div className="relative top-0 h-full w-full">
                <img src={image} alt="preview" className={`w-full h-full  ${activeImageSide === 4 && "ransform rotate-180 "}`} />
            </div>

            {
                isQuickScanTaken ?
                    <div className="z-10 absolute bottom-5 w-full flex justify-evenly">
                        <button className=" block w-40  h-12 bg-red-unilever text-white font-semibold rounded  md:h-16 md:text-xl md:w-52"
                        onClick={() => quickScanSubmit(imageFile)} disabled={quickScanLoading} >
                            {
                                quickScanLoading ? <span> Sedang diproses... </span> : <span> Kirim foto </span>
                            }
                        </button>
                    </div> :
                    <div className="z-10 absolute bottom-5 w-full flex justify-evenly">
                        <button
                            className=" block w-40 h-12 bg-white border-2 text-uv-dark-blue border-uv-dark-blue  font-semibold rounded-full  md:h-16 md:text-xl md:w-52"
                            onClick={handleReshoot}>
                            <span > Chụp ảnh lại

                            </span> </button>
                        <button className=" block w-40  h-12 bg-uv-dark-blue text-white font-semibold rounded-full md:h-16 md:text-xl md:w-52 "
                        onClick={next} disabled={isLoading}

                        >
                            {
                                isLoading ? <div className="loader-container">
                                    <div className="loader dark-loader"></div>
                                </div> : <span > Tiếp theo</span>
                            }
                        </button>
                    </div>
            }

            {reshoot && <ReshootPopup setReshoot={setReshoot} errorType={errorType} setCamera={setCamera} setDemo={setDemo} />}
        </div>


    )
}


export default Preview