import front from "../../../assets/demoImages/front.png"
import upperJaw from "../../../assets/demoImages/upper-reversed.png"
import lowerJaw from "../../../assets/demoImages/lower.png"
import left from "../../../assets/demoImages/left.png"
import right from "../../../assets/demoImages/right.png"
import face from "../../../assets/demoImages/demoImage.jpg"

const steps = [
    {
        title : "Front teeth",
        translate : "Bước 1: Chụp răng cửa & mặt trước cả hàm",
        teeth_side: "răng cửa & mặt trước cả hàm",
        image: front,
        tips: ["Canh để mặt trước của răng nằm vừa trong khung hình"]
    },
    {
        title: "Left side",
        translate: "Left side",
        teeth_side: "",
        image: right,
        tips: ["Pull the corner of your mouth as back as possible"],
    },
    {
        title: "Right side",
        translate: "Right side",
        teeth_side: "",
        image: left,
        tips: ["Pull the corner of your mouth as back as possible"]
    },
    {
        title: "Lower jaw",
        translate: "Bước 2: Chụp hàm dưới",
        teeth_side: "hàm dưới",
        image: lowerJaw,
        tips: ["Mở miệng đủ lớn để thấy hàm dưới trong khung ảnh"]
    },
    {
        title: "Upper jaw",
        translate: "Bước 3: Chụp hàm trên",
        teeth_side: "hàm trên",
        image: upperJaw,
        tips: ["Open your mouth tilt your head backwards", "Fit your teeth within the template."]
    },
    {
        title: "Face",
        image : face,
        tips: []
    }
]


export default steps
