import { useNavigate, useParams } from "react-router-dom"
import close from "../../../assets/Icons/close.png"
import blur from "../../../assets/invalidImage/blur.png"
import invalid_image from "../../../assets/invalidImage/invalid-image.png"
import light from "../../../assets/invalidImage/light.png"
import showDemo from "../../../assets/invalidImage/show-demo.png"
import error from "../../../assets/svg/failed.svg"
import { fontStyle, fontWeight, buttonStyle, companyBucket } from "../../../Utils/tpscanStyle"
import { useTranslation } from "react-i18next"
const ReshootPopup = (props) => {
    const { dentalOfficeName } = useParams();
    const { t } = useTranslation("generalerror")

    const { setReshoot, errorType, setCamera, setDemo } = props
    const navigate = useNavigate()
    const company_style_details = JSON.parse(
        localStorage.getItem("company_style_details")
    );
    const fontWeight = {
        fontWeight: company_style_details
            ? `${company_style_details.onboarding_text_font_weight}`
            : 900,
    };
    const buttonStyle = {
        backgroundColor: company_style_details
            ? `${company_style_details.button_background_color}`
            : "#000000",
    };

    const Failed = () => {
        return (
            <div className="h-full bg bg-transparent w-full flex justify-center items-center ">
                <div className="bg-white w-3/4 flex flex-col items-center p-5 rounded-md">
                    <div className="text-end w-full">
                        <button
                            type="button"
                            onClick={() => { setReshoot(false) }}>
                            <img src={close} className="w-6 h-6" alt="close" />
                        </button>
                    </div>
                    <img src={error} alt="error" className=" w-40 h-40" />
                    <p className="text-center text-peri-blue my-5 font-cera-pro-medium"> {t("sorry")} </p>
                    {companyBucket !== "done" && <div className=" w-full ">
                        <button
                            type="button"
                            className="font-cera-pro-medium bg-peri-blue text-white w-full h-11  text-base shadow-2xl rounded-full"
                            onClick={() => {
                                navigate(-1)
                            }}>
                            {t("goBack")}</button>
                    </div>}
                </div>
            </div>
        )
    }

    const MouthFarAway = () => {
        return (
            <div className="relative w-full bg-white " style={{ height: "90%", maxHeight: "90%" }}>
                <div className="flex justify-center mt-1  px-4">
                    <p className="dragger rounded"></p>
                </div>
                <div className="text-end mt-2  px-4">
                    <button type="button" onClick={() => { setReshoot(false) }}> <img src={close} className="w-7 h-7" alt="close" />  </button>
                </div>
                <div className="text-base py-3 px-4">
                    <p className=" text-peri-blue font-medium my-1 text-3xl text-center font-cera-pro-medium" > {t("bringCloser")}  </p>
                </div>
                <div className="absolute bottom-0">
                    <img src={showDemo} className=" h-auto max-w-full" alt="sample" />
                </div>
                <div className="absolute bottom-0 w-full p-5 text-center">
                    <button type="button" className="text-white text-xl bg-peri-blue w-4/5 py-2 rounded-full font-cera-pro-medium font-semibold" onClick={() => { setCamera(true) }}>
                        {t("retake")}


                    </button>
                </div>
            </div>

        )

    }

    const Blurness = () => {
        return (
            <div className="relative  w-4/5 bg-white rounded-lg py-4">
                <p className="text-[#C32D87] text-4xl  text-center font-cera-pro-medium font-semibold">{t("error")}</p>
                <div className="text-base py-3 px-4 text-peri-blue list-disc">
                    <p className="font-medium my-1 font-cera-pro-medium" > {t("header")} </p>
                    <ul className=" list-disc">
                        <li className="my-3 ms-5 font-cera-pro-medium"> {t("blurPhoto")}   </li>
                    </ul>
                </div>
                <div className="flex flex-col items-center ">
                    <button type="button" className="font-cera-pro-medium font-semibold w-4/5 text-xl py-2 rounded-full text-peri-blue border  border-peri-blue mb-2 " onClick={() => { setDemo(true) }} > {t("ViewReference")} </button>
                    <button type="button" className="font-cera-pro-medium font-semibold w-4/5 text-xl py-2 rounded-full bg-peri-blue text-white" onClick={() => { setCamera(true) }} > {t("retake")}  </button>
                </div>
            </div>
        )
    }


    const Inavlid = () => {
        return (
            <div className="relative  w-4/5 bg-white rounded-lg py-4">
                <p className="text-[#C32D87] text-4xl font-cera-pro-medium font-semibold text-center"> {t("error")}</p>
                <div className="text-base py-3 px-4 text-peri-blue list-disc ">
                    <p className="my-1 font-cera-pro-medium " >  {t("header")}  </p>

                    <ul className=" list-disc ms-5 py-3 font-cera-pro-medium">
                        <li className="">{t("poorLight")}
                        </li>
                        <li className="">{t("didntMatch")}
                        </li>

                    </ul>
                </div>
                <div className="flex flex-col items-center ">
                    <button type="button" className=" font-cera-pro-medium font-semibold w-4/5 text-2xl py-2 rounded-full text-peri-blue border  border-peri-blue mb-2 " onClick={() => { setDemo(true) }} > {t("ViewReference")}  </button>
                    <button type="button" className=" font-cera-pro-medium font-semibold  w-4/5 text-xl py-2 rounded-full bg-peri-blue text-white" onClick={() => { setCamera(true) }} > {t("retake")} </button>
                </div>
            </div>
        )
    }


    return (
        <div className="h-full z-20 absolute w-full top-0 bg-pink-500/[0.8] backdrop-blur-sm flex items-center justify-center">
            {errorType === "mouth far away" && <MouthFarAway />}
            {errorType === "blur detected" && <Blurness />}
            {errorType === "failed" && <Failed />}
            {errorType === "general" && <Inavlid />}
        </div>
    )
}

export default ReshootPopup