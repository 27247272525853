import { useDispatch, useSelector } from "react-redux";
import back from "../../../assets/Icons/Back.png"
import close from "../../../assets/Icons/close.png"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createScan, submitScan } from "../../../Services/APIs/scan";
import { clearImages } from "../../../Store/fullScan";
import Form from "../Form";
import error from "../../../assets/svg/failed.svg";
import { useTranslation } from "react-i18next";


const SubmitPhotos = () => {
    const { t, i18n } = useTranslation("submitscreen")
    const navigate = useNavigate()
    const { images } = useSelector((state) => state.fullScan);
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [formOpen, setFormOpen] = useState(false);
    const [isScanCompleted, setComplete] = useState(false);
    const [isFormCompleted, setFormCompleted] = useState(false);
    const goBack = () => {
        window.history.back()
    }

    const submitReport = () => {
        setFormOpen(true)
        // isScanCompleted ? isFormCompleted ? submit() : setFormOpen(true) : submit()
    }

    const submit = async () => {
        setLoading(true)

        const info = {
            uid: JSON.parse(localStorage.getItem('usertpi') || "{}").usertpi,
            folderName: JSON.parse(localStorage.getItem('usertpi') || "{}").usertpi,
            company: JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket,
            lang: localStorage.getItem("i18nextLng"),
        }
        await submitScan(info).then(async (response) => {
            if (localStorage.getItem("usertpi")) {
                localStorage.setItem(
                    "tpscore",
                    JSON.stringify(response.data?.data?.data)
                );
            }
            const { images, ...mlResults } = response?.data?.data?.data;
            const { pdf_url, name, ...report_score } = mlResults;
            localStorage.setItem("pdf", `${name}.pdf`);
            createReport({ pdf_url, report_score });
        }).catch((error) => {
            setLoading(false);
            setError(true);
            setFormOpen(false)
        });
    }

    const createReport = async (data) => {
        try {
            const tooth = {
                front_teeth: "Front teeth",
                upper_jaw: "Upper jaw",
                lower_jaw: "Lower jaw",
                left_side: "Left side",
                right_side: "Right side",
                face: "Face"
            };
            const report = {
                uid: JSON.parse(localStorage.getItem('usertpi') || "{}").usertpi,
                company: JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket,
                is_full_scan: true,
                language: i18n.language,
                ...data
            };
            for (const key in tooth) {
                images.forEach((eachObj) => {
                    if (tooth[key] === eachObj.title) {
                        report[key] = eachObj.file;
                    }
                });
            }
            await createScan(report).then((response) => {
                localStorage.setItem("response", JSON.stringify(response?.data?.data?.report));
                dispatch(clearImages());
                localStorage.setItem("scoreCard", true);
                localStorage.setItem("isScanCompleted", true)
                localStorage.removeItem("reloaded")
                navigate("/peri-gum/report-summary")
                setLoading(false)
            }).catch((error) => {
                setLoading(false);
                setError(true);
                setFormOpen(false)
            });
        } catch (error) {
            setLoading(false);
            setError(true);
            setError(true)
            setFormOpen(false)

        }


    };

    const ErrorUI = () => {
        return (
            <div className="h-full absolute top-0 z-10 w-full flex justify-center items-center bg-black/[0.3] backdrop-blur-sm">
                <div className="bg-white w-3/4 flex flex-col items-center p-5 rounded-md shadow-sm">
                    <div className="text-end w-full">

                        <button
                            type="button"
                            onClick={() => { setError(false) }}>
                            <img src={close} className="w-6 h-6" alt="close" />
                        </button>

                    </div>
                    <img src={error} alt="error" className=" w-40 h-40" />
                    <p className="text-center my-5 font-cera-pro-medium">
                        {t("sorry")}
                    </p>
                    <button
                        type="button"
                        className="bg-black text-white w-full h-11 text-base shadow-2xl font-cera-pro-medium"
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        {t("goBack")}
                    </button>
                </div>
            </div>
        );
    };


    return (
        <div className="h-full">
            {
                formOpen ? <Form setFormOpen={setFormOpen} setFormCompleted={setFormCompleted} submit={submit} /> :
                    <div className="h-full w-full bg-[#b2b2b2] flex flex-col justify-between items-center">
                        {isError && <ErrorUI />}
                        <div className="w-full">
                            <div className="flex justify-between py-3 px-3">
                                <button type="button" onClick={goBack}>
                                    <img src={back} alt="back" className="h-7" />
                                </button>
                                <button type="button" onClick={goBack}>
                                    <img src={close} alt="back" className="h-7" />

                                </button>
                            </div>
                            <p className="text-[34px] text-white font-semibold text-center mt-10 font-cera-pro-medium"> {t("header")} </p>
                        </div>

                        <div className="">
                            {
                                images.length === 0 ?
                                    <p>{t("text")}</p>
                                    :
                                    <div className="flex overflow-auto  py-3 my-5">
                                        {images.map((image, i) => {
                                            return (
                                                <img src={image.dataUri} key={i} alt="preview" className="rounded-2xl mx-4 w-4/5" />
                                            )
                                        })
                                        }
                                    </div>
                            }
                        </div>
                        <button
                            type="button"
                            className=" text-peri-blue bg-white rounded-full px-6 py-3 font-semibold text-2xl mb-16 w-11/12 font-cera-pro-medium"
                            onClick={submitReport}
                            disabled={isLoading}
                        >
                            {
                                isLoading ? <div className="loader-container my-1">
                                    <div className="loader dark-loader"></div>
                                </div> : <span> {t("submit")} </span>
                            }
                        </button>
                    </div>
            }

        </div>

    )
}

export default SubmitPhotos