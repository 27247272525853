import close from "../../../assets/Icons/close.png"
import { useNavigate } from "react-router-dom";
import toothlensLogo from "../../../assets/illustrations/Toothlens Logo.jpeg"

const ThankYou = () => {
    const navigate = useNavigate()

    return (
        <div>
            <div className="h-screen bg-custom-gradient text-center flex flex-col justify-center items-center px-3">
                <div className="absolute top-0 right-0 p-3">
                    <button type="button"
                        onClick={() => { navigate(-2) }} >
                        <img src={close} alt="close_img" width="26px" />
                    </button>
                </div>

                <div className=" flex justify-center">
                    <img src={toothlensLogo} alt="done" />
                </div>
                <div className=" flex justify-center">
                    <div
                        className="mt-8"
                        style={{
                            color: "#2C3E50",
                            fontFamily: "Poppins",
                            fontWeight: "700",
                            fontSize: "16px",
                        }}
                    >
                        <p> Your photos have been submitted</p>
                        <p>Our representative will get in touch with you</p>
                    </div>
                </div>
                <p className="mt-5 text-3xl"
                    style={{
                        color: "#2C3E50",
                        fontFamily: "Poppins",
                        fontWeight: "700",
                    }}
                >
                    Thanks
                </p>

            </div>
        </div>
    )
}

export default ThankYou