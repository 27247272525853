import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fontStyle, fontWeight, companyBucket } from "../../../Utils/tpscanStyle"
import { postLogDetails } from "../../../Services/APIs/appDetails"
import teethSides from "../../../Utils/teethSideNames"
import crop from "../../../Utils/crop"
import { imageUpload } from "../../../Services/APIs/scan"
import constant from "./constantNames"
import { addImages } from "../../../Store/fullScan"
import { encrypt } from "../../../Utils/hmac"
import dataURItoBlob from "../../../Utils/dataUriToBlob"
import ReshootPopup from "./reshootError"
import { useTranslation } from "react-i18next"


const Preview = (props) => {
    const { t, i18n } = useTranslation("demoscreen")
    const { image, setCamera, setToothPicker, activeImageSide, setDemo, setImageSide } = props
    const dispatch = useDispatch()

    const [reshoot, setReshoot] = useState(false)
    const [errorType, setImageError] = useState('')
    const [isLoading, setLoading] = useState(false)

    const handleReshoot = () => {
        setCamera(true)
        postLogDetails({
            uid: JSON.parse(localStorage.getItem("usertpi")).usertpi,
            app: JSON.parse(localStorage.getItem("companyBucket")).companyBucket + `-${i18n.language}`,
            payLoad: `reshooting ${teethSides[activeImageSide]} teeth`
        })
    }

    const next = async () => {
        const rotate = activeImageSide === 4
        const croppedImage = await crop(image, 3 / 2, rotate).then(canvas => {
            return canvas.toDataURL("image/jpeg", 0.75);
        })
        await upload(croppedImage)
    }

    const upload = async (dataUri) => {
        const data = {
            uid: JSON.parse(localStorage.getItem('usertpi') || "{}").usertpi,
            folderName: JSON.parse(localStorage.getItem('usertpi') || "{}").usertpi,
            company: JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket,
            teethSide: `${teethSides[activeImageSide]} teeth`,
            language: i18n.language
        }
        const encrypted = encrypt(data)
        const blob = dataURItoBlob(dataUri)
        const file = new File([blob], "report_image.jpeg", { type: "image/jpeg" });
        const formData = new FormData();
        formData.append("report_image", file);
        formData.append("_n", encrypted);
        setLoading(true)
        setReshoot(false)
        await imageUpload({ formData }).then((response) => {
            setLoading(false)

            const required = {
                title: constant[activeImageSide].title,
                dataUri: URL.createObjectURL(blob),
                file: response.data.data?.imageName,
                isReshoot: response.data.data?.is_reshoot,
                mouth_detection_message: response.data.data?.mouth_detection_message
            }
            if (required.isReshoot) {
                setReshoot(true)
                if (response.data.data?.mouth_detection_message === "mouth far away") {
                    setImageError("mouth far away")
                } else if (response.data.data?.mouth_detection_message === "blur detected") {
                    setImageError("blur detected")
                }
                else {
                    setImageError("general")
                }
            } else {
                dispatch(addImages(required))
                if (localStorage.getItem("isAutomated") && activeImageSide < 2) {
                    setImageSide(activeImageSide + 1)
                    setDemo(true)
                } else {
                    setToothPicker(true)
                }
            }

        }).catch((error) => {
            console.log(error)
            setLoading(false)
            setReshoot(true)
            setImageError("failed")
        })


    }

    return (
        <div className="h-screen">
            <div className="relative top-0 h-full w-full">
                <img src={image} alt="preview" className="w-full object-cover h-full" />
            </div>

            <div className="z-10 absolute bottom-5 w-full flex justify-evenly">
                <button
                    className=" block w-40 h-12 bg-white text-black font-semibold rounded"
                    onClick={handleReshoot}>
                    <span className=" text-peri-blue text-lg font-cera-pro-medium"> {t("reshoot")}
                    </span>
                </button>
                <button
                    className=" block w-40  h-12 bg-white text-black font-semibold rounded"
                    onClick={next}
                    disabled={isLoading}
                >
                    {
                        isLoading ? <div className="loader-container">
                            <div className="loader dark-loader"></div>
                        </div> : <span className=" text-peri-blue text-xl font-cera-pro-medium"> {t("next")} </span>
                    }
                </button>
            </div>
            {reshoot && <ReshootPopup setReshoot={setReshoot} errorType={errorType} setCamera={setCamera} setDemo={setDemo} />}

        </div>
    )
}

export default Preview;