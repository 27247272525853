import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import 'react-html5-camera-photo/build/css/index.css';
import back from "../../assets/Icons/Back-white.png"
import demoIcon from "../../assets/Icons/image-reference.png"
import teethListIcon from "../../assets/Icons/teeth-list-icon.png"
import flashOn from "../../assets/Icons/flash-on.png"
import flashOff from "../../assets/Icons/flash-off.png"
import DemoScreens from './demoScreens';
import Preview from './preview';
import "./index.css"
import steps from './steps';
import PermissionPopup from './cameraPermissionPopup';
import { GetBrowserDetails } from '../../Utils/deviceDetails';
import { GetMobileOperatingSystem } from '../../Utils/getOS';
import { fontStyle, fontWeight, buttonStyle, companyBucket } from "../../Utils/tpscanStyle"
import FrontCameraError from './frontCameraError';
import { postLogDetails } from '../../Services/APIs/appDetails';
import teethSides from '../../Utils/teethSideNames';
import { notifyCameraError } from '../../Services/APIs/scan';
import { getCompleteDeviceDetails } from '../../Utils/comepleteDeviceInfo';
import Overlapper from '../StarInsurance/Scan/overlapper';
import PreviewFace from '../StarInsurance/Scan/previewFace';

const Shoot = (props) => {
    const [facialMode, setFacialMode] = useState(FACING_MODES.USER)
    const [frontCameraError, setFrontCameraError] = useState(false)
    const { activeImageSide, setToothPicker, quickScan, setImageSide } = props
    const navigate = useNavigate()
    const [isDemo, setDemo] = useState(true)
    const [isCamera, setCamera] = useState(true)
    const [capturedImage, setCapturedImage] = useState()
    const [flashLight, setFlashLight] = useState(false)
    const [mediaStream, setStream] = useState(null)
    const [errorType, setErrorType] = useState({ isError: false, type: "ios" })
    const OS = GetMobileOperatingSystem()   // operating system
    const uid = JSON.parse(localStorage.getItem("usertpi") || '{}').usertpi
    const app = JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket


    useEffect(() => {
        // Clean-up function to reset flashlight state when component unmounts or user navigates away
        return () => {
            if (mediaStream && flashLight) {
                setFlashLight(false)
                // torchMechanism(mediaStream, false); // Turn off flashlight when component unmounts
            }
        };
    }, [mediaStream]);


    const handleWithFacialMode = () => {
        navigator.mediaDevices.getUserMedia({ video: { facingMode: FACING_MODES.USER } })
            .then((stream) => {
                setFacialMode(FACING_MODES.USER)
                postLogDetails({ uid, app, payLoad: `iOS - found front camera, video-track-length-${stream.getVideoTracks().length}` })
            })
            .catch((error) => {
                postLogDetails({ uid, app, payLoad: `ios- front camera error  ${JSON.stringify(error)} so starting rear-camera` })
                notifyCameraError({ info: { ...getCompleteDeviceDetails(), uid, app, error: JSON.stringify(error) } })
                setFrontCameraError(true)
                setFacialMode(FACING_MODES.ENVIRONMENT)
            });
    }


    const handleCameraType = () => {
        OS === "iOS" ? handleWithFacialMode() : handleWithLabel()
    }

    const handleWithLabel = () => {
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                const videoDevices = devices.filter(device => device.kind === 'videoinput');

                const frontCamera = videoDevices.find(device => device.label.toLowerCase().includes('front'));
                if (frontCamera) {
                    setFacialMode(frontCamera.deviceId); // Set the deviceId directly
                    postLogDetails({ uid, app, payLoad: "Android - found front camera" })

                } else {
                    postLogDetails({ uid, app, payLoad: `Android- front camera error  ${JSON.stringify(videoDevices)} so starting rear-camera` })
                    notifyCameraError({ info: { ...getCompleteDeviceDetails(), uid, app, error: JSON.stringify(videoDevices) } })
                    setFacialMode(FACING_MODES.ENVIRONMENT); // Fallback to rear camera
                    setFrontCameraError(true)
                }
            })
            .catch(err => {
                postLogDetails({ uid, app, payLoad: `Android- media devices error ${JSON.stringify(err)}` })
            });
    }

    const stopCamera = () => {
        // console.log("Tracks before stopping:", mediaStream.getTracks()); // Log tracks
        if (mediaStream) {
            mediaStream.getTracks().forEach((track) => {
                // console.log(`Stopping track with kind: ${track.kind}, readyState: ${track.readyState}`);
                postLogDetails({ uid, app, payLoad: `Stopping track with kind: ${track.kind}, readyState: ${track.readyState}` })
                track.stop()
                // console.log(`Track stopped. New readyState: ${track.readyState}`);
                postLogDetails({ uid, app, payLoad: `Track stopped ${track.kind}, readyState: ${track.readyState}` })
            });
            setStream(null);
        }
    };

    const restartCamera = () => {
        navigator.mediaDevices.getUserMedia({ video: { facingMode: facialMode } })
            .then((stream) => {
                setStream(stream)
                postLogDetails({ uid, app, payLoad: `Restarted Streaming` })
            })
            .catch((err) => {
                postLogDetails({
                    uid: JSON.parse(localStorage.getItem("usertpi")).usertpi,
                    app: JSON.parse(localStorage.getItem("companyBucket")).companyBucket,
                    payLoad: `Stream error ${JSON.stringify(err)}`
                })
            });
    };

    const handleTakePhoto = (dataUri) => {
        const base64String = dataUri.split(',')[1]; // Remove the data URI scheme part
        const base64StringLength = base64String.length;
        const estimatedSizeInBytes = (base64StringLength * 3) / 4; // Base64 size formula
        const estimatedSizeInKB = estimatedSizeInBytes / 1024; // Convert to KB
        const estimatedSizeInMB = estimatedSizeInKB / 1024; // Convert to MB
        setCapturedImage(dataUri)
        postLogDetails({
            uid: JSON.parse(localStorage.getItem("usertpi")).usertpi,
            app: JSON.parse(localStorage.getItem("companyBucket")).companyBucket,
            payLoad: `captured ${teethSides[activeImageSide]} teeth, ${estimatedSizeInMB.toFixed(5)} MB`,
        })
        setTimeout(() => {
            setCamera(false)
        }, 400)
        stopCamera();
        setTimeout(() => restartCamera(), 500); // Restart after a short delay
    }

    const cameraStarted = (stream) => {
        setStream(stream)
        handleCameraType()
        setErrorType({ isError: false, type: "ios" })
    }

    const checkError = () => {
        const browser = GetBrowserDetails();

        if (browser.name === "Mobile Chrome") {
            navigator.permissions.query({ name: 'camera' }).then((res) => {
                if (res.state === "denied") {
                    if (OS === 'iOS') {
                        setErrorType({ isError: true, type: "ios" })
                    } else {
                        setErrorType({ isError: true, type: "android" })
                    }
                }
            }).catch((error) => {
                setErrorType({ isError: true, type: "ios" })
            })

        } else {
            setErrorType({ isError: true, type: "ios" })
        }

    }

    const OvuleShape = () => {
        return (
            <div className={`ovule-container w-full flex flex-col justify-center items-center `}>
                <div className={`relative ovule ovule-${activeImageSide} ${activeImageSide === 4 && isCamera && "ovule-4-reversed"}  `}>
                    <p
                        className={`absolute -bottom-10 w-full text-center text-base text-white ${activeImageSide === 4 && isCamera && "ransform rotate-180 "} `}
                        style={(companyBucket == "done" || companyBucket === "hascompanies") ? { fontWeight: 400, ...fontStyle } : { ...fontWeight, ...fontStyle }}>
                        {steps[activeImageSide].title}
                    </p>
                </div>
            </div>
        )
    }

    const TopLayer = () => {
        return (
            <div className='camera-top-layer-2 h-full absolute top-0 text-white w-full'>
                <div className='flex justify-between items-center h-13 px-2'>
                    <button type='button' onClick={() => { localStorage.getItem("isAutomated") ? window.history.back() : setToothPicker(true) }}> <img src={back} alt="back" className="w-8 h-8" /> </button>
                    <div className='pt-2'>
                        <button type='button' onClick={() => { setDemo(true) }} > <img src={demoIcon} alt="back" className="w-12 h-12 " /> </button>
                        {/* {!quickScan && <button type='button' onClick={() => { setToothPicker(true) }}> <img src={teethListIcon} alt="back" className="w-12 h-12 mx-2" /> </button>} */}
                    </div>
                </div>
                <p
                    className={`text-base mt-16 text-center ${activeImageSide === 4 && isCamera && "ransform rotate-180 "}`}
                    style={(companyBucket == "done" || companyBucket === "hascompanies") ? { fontWeight: 400, ...fontStyle, } : { ...fontWeight, ...fontStyle, }}>
                    {isCamera ? "Tap anywhere to capture" : `Preview of the ${steps[activeImageSide].title}`}
                </p>
            </div>
        )
    }


    return (
        <>
            {isDemo ? <DemoScreens activeImageSide={activeImageSide} setToothPicker={setToothPicker} setDemo={setDemo} setCamera={setCamera} quickScan={quickScan} /> :
                <div className=''>
                    {errorType.isError && <PermissionPopup info={errorType} setErrorType={setErrorType} />}
                    {
                        isCamera ?
                            <>
                                <Camera
                                    onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                                    idealFacingMode={facialMode}
                                    isFullscreen={false}
                                    isMaxResolution={OS === "iOS" ? false : true}
                                    imageType={IMAGE_TYPES.JPG}
                                    isImageMirror={facialMode !== FACING_MODES.ENVIRONMENT}
                                    imageCompression={OS === "iOS" ? 0.5 : 0.6}
                                    sizeFactor={1}
                                    videoConstraints={{
                                        facingMode: { exact: "user" }, // Force front camera
                                        width: { ideal: window.innerWidth },
                                        height: { ideal: window.innerHeight },
                                        aspectRatio: { ideal: window.innerWidth / window.innerHeight }
                                    }}
                                    onCameraStart={cameraStarted}
                                    onCameraError={checkError}
                                    className="WebCam"
                                />
                                {!errorType.isError && frontCameraError && <FrontCameraError back={setFrontCameraError} />}

                            </> :
                            <>
                                {
                                    activeImageSide === -1 ?
                                        <PreviewFace
                                            setImageSide={setImageSide}
                                            image={capturedImage}
                                            setCamera={setCamera}
                                            setToothPicker={setToothPicker}
                                            activeImageSide={activeImageSide}
                                            setDemo={setDemo}
                                            quickScan={quickScan}
                                        /> :
                                        <Preview
                                            setImageSide={setImageSide}
                                            image={capturedImage}
                                            setCamera={setCamera}
                                            setToothPicker={setToothPicker}
                                            activeImageSide={activeImageSide}
                                            setDemo={setDemo}
                                            quickScan={quickScan}
                                        />

                                }
                            </>
                    }
                    {
                        activeImageSide === -1 ? isCamera ? <Overlapper /> : null :
                            <>
                                <OvuleShape />
                                <TopLayer />
                            </>
                    }


                </div >
            }
        </>
    )
}

export default Shoot